export default {
  palette: {
    primary: '#6a82c2',
    primaryLight: '#8397cc',
    primaryDark: '#5d73ad',
    green: '#28a97c',
    greenDark: '#249e74',
    black: '#2e384c',
    white: '#fff',
    orange: '#fd8a74',
    blue: '#2e80bb',
    grey: '#999',
    red: '#d64a54'
  },
  color: {
    primary: '#2e384c',
    secondary: '#818591'
  },
  background: {
    primary: '#fafafa'
  },
  boxShadow: {
    modal: '0 2px 15px rgba(0, 0, 0, 0.11)'
  },
  border: {
    primary: '1px solid #eaeaec'
  }
}
