import React from 'react'
import styled from 'styled-components'
import { path, prop, propOr } from 'ramda'
import numberFormat from 'utils/numberFormat'
import { PAYMENT_TYPE } from 'constants/backend'
import moment from 'moment'
import Button from 'components/Forms/Button'
import { useSelector } from 'react-redux'
import * as STATE from 'constants/stateNames'

const ProductDetailBlock = styled.div`
  display: flex;
  align-items: ${({ alignItems }) => alignItems || 'flex-start'};
  margin-bottom: 20px;
`
const ProductDetailName = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 129.96%;
  color: #818591;
  mix-blend-mode: normal;
  width: 200px;
  min-width: 200px;
`
const ProductDetailValue = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 129.96%;
  color: #2e384c;
  mix-blend-mode: normal;
`
const Line = styled.div`
  border-bottom: 1px solid #eaeaec;
  margin: 25px 0;
`
const TotalPriceBlock = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 129.96%;
  color: ${({ theme }) => theme.palette.green};
`
const DeliveryValue = styled('span')`
  span{
    color: ${({ theme }) => theme.palette.primary};
    font-weight: 500;
  }
`

const MyOrderDetails = (props) => {
  const { item } = props

  // Data
  const userInfo = useSelector(prop(STATE.USER_INFO))
  const userInfoData = prop('data', userInfo)

  // Const
  const address = path(['deliveryAddress'], item)
  const totalPrice = path(['totalPrice'], item)
  const userFullname = prop('fullName', userInfoData)
  const userPhone = prop('username', userInfoData)
  const secondPhone = path(['secondPhone'], item)
  const secondFullname = path(['secondFullname'], item)
  const deliveryType = path(['deliveryType'], item)
  const deliveryTypeName = prop('name', deliveryType)
  const deliveryTypeCost = propOr(0, 'cost', deliveryType)
  const paymentType = prop('paymentType', item)
  const deliveryDate = prop('deliveryDate', item)
  const payLink = path(['paymentLink'], item)

  // Handlers
  const handlePay = () => window
    ? window.open(payLink, '_blank')
    : null

  return (
    <>
      <ProductDetailBlock>
        <ProductDetailName>
          Адрес доставки
        </ProductDetailName>
        <ProductDetailValue>
          {address}
        </ProductDetailValue>
      </ProductDetailBlock>
      {(!secondPhone && !secondFullname) &&
      <ProductDetailBlock>
        <ProductDetailName>
          Мои данные
        </ProductDetailName>
        <ProductDetailValue>
          {userPhone}{(userPhone && userFullname) && ', '}{userFullname}
        </ProductDetailValue>
      </ProductDetailBlock>}
      {(secondPhone || secondFullname) &&
      <ProductDetailBlock>
        <ProductDetailName>
          Данные получателя
        </ProductDetailName>
        <ProductDetailValue>
          {secondPhone}{(secondPhone && secondFullname) && ', '}{secondFullname}
        </ProductDetailValue>
      </ProductDetailBlock>}
      <Line />
      <ProductDetailBlock>
        <ProductDetailName>
          Тариф доставки
        </ProductDetailName>
        <ProductDetailValue>
          <DeliveryValue>
            {deliveryTypeName && `${deliveryTypeName}\u00A0-\u00A0`}
            <span>
              {deliveryTypeCost > 0 ? numberFormat(deliveryTypeCost, 'сум') : 'Бесплатно'}
            </span>
          </DeliveryValue>
        </ProductDetailValue>
      </ProductDetailBlock>
      <ProductDetailBlock>
        <ProductDetailName>
          Способ оплаты
        </ProductDetailName>
        <ProductDetailValue>
          {PAYMENT_TYPE[paymentType]}
        </ProductDetailValue>
      </ProductDetailBlock>
      <Line />
      <ProductDetailBlock
        alignItems={'center'}
      >
        <ProductDetailName>
          Итоговая сумма
        </ProductDetailName>
        <ProductDetailValue>
          <TotalPriceBlock>{numberFormat(totalPrice, 'сум')}</TotalPriceBlock>
        </ProductDetailValue>
        {((paymentType === 'click' || paymentType === 'payme') && payLink) &&
        <Button
          text={'Оплатить'}
          type={'button'}
          style={{
            padding: '5px 30px',
            minHeight: '35px',
            marginLeft: 'auto'
          }}
          onClick={handlePay}
        />}
      </ProductDetailBlock>
      {deliveryDate &&
      <>
        <Line />
        <ProductDetailBlock>
          <ProductDetailName>
            Дата доставки
          </ProductDetailName>
          <ProductDetailValue>
            {moment(deliveryDate).format('DD MMM YYYY')}
          </ProductDetailValue>
        </ProductDetailBlock>
      </>}
    </>
  )
}

export default MyOrderDetails
