import React from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { path, isEmpty, find, propEq, pathOr, prop } from 'ramda'
import NoImage from 'images/NoImage.png'
import { setItemToCart } from 'components/Cards/storage'
import { Row } from 'components/Grid'
import TrashIcon from 'icons/TrashIcon'
import { CartButton } from 'components/UI/Button'
import Price from 'components/UI/Price'
import SalePrice from 'components/UI/SalePrice'

const Card = styled.div`
  background: #fff;
  border-radius: 5px;
  margin-bottom: 60px;
`
const Description = styled('div')`
  width: 310px;
  margin-right: 35px;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: center;
`
const ProductName = styled.div`
  font-size: 14px;
  line-height: 129.96%;
  color: #242f3b;
  margin-bottom: 6px;
  font-weight: 500;
`
const Counter = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 25px;
`
const DeleteButton = styled('div')`
  cursor: pointer;
  border: none;
  outline: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px 0 auto;
  svg{
    stroke: #aeb2b7;
  }
  &:hover svg{
    stroke: #eb4225;
  }
`
const Img = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95px;
  height: 95px;
  padding: 5px;
  margin-right: 18px;
  img{
    width: 100%;
    height: 100%;
  }
`
const ContentPosition = styled.div``
const ProductArticul = styled.div`
  font-size: 14px;
  line-height: 129.96%;
  color: #818591;
`
const PriceWrap = styled('div')`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-end;
  & > *:not(:last-child){
    margin-bottom: 3px;
  }
  b{
    color: #28a97c;
    font-size: 20px;
    line-height: 26px;
  }
`
const ProductRow = styled('div')`
  position: relative;
  padding: 20px 0;
  border-bottom: 1px solid #e7e8ea;
  width: calc(100% - 40px);
  margin: 0 20px;
  :nth-last-child(1){
    border-bottom: none;
  }
`

const Cart = props => {
  const { onDelete, products = [] } = props
  const dispatch = useDispatch()
  const handleAdd = (product, value, mathValue) => {
    return dispatch(setItemToCart((+value + mathValue).toFixed(1), product))
  }
  const handleRemove = (product, value, mathValue) => {
    return dispatch(setItemToCart((+value - mathValue).toFixed(1), product))
  }

  return (
    <div>
      {isEmpty(products) ? (
        <ContentPosition>
          <Card>
            <ProductRow>
              <Row>
                Ваша корзина пуста
              </Row>
            </ProductRow>
          </Card>
        </ContentPosition>
      ) : (
        <Card>
          {products.map((product, key) => {
            const images = pathOr([], ['images'], product)
            const isPrimary = find(propEq('isPrimary', true))(images)
            const image = path(['file'], isPrimary)
            const name = path(['name'], product)
            const id = path(['id'], product)
            const price = +path(['price'], product) || 0
            const oldPrice = prop('oldPrice', product)
            const amount = path(['amount'], product)
            const barcodes = path(['barcodes', 0], product)

            const measurement = prop('measurement', product)
            const measurementName = prop('name', measurement)

            const isCustomWeight = measurementName &&
              (measurementName.toLowerCase() === 'кг' || measurementName.toLowerCase() === 'kg')

            const mathValue = isCustomWeight ? 0.2 : 1

            return (
              <ProductRow key={key}>
                <Row
                  justify={'space-between'}
                >
                  <Img>
                    <img
                      src={image || NoImage}
                      alt={'image'}
                    />
                  </Img>
                  <Description>
                    <ProductName>{name}</ProductName>
                    <ProductArticul>#{barcodes}</ProductArticul>
                  </Description>
                  <Counter>
                    <CartButton
                      amount={amount}
                      onAdd={() => handleAdd(product, amount, mathValue)}
                      onRemove={() => handleRemove(product, amount, mathValue)}
                      measurement={measurementName}
                    />
                  </Counter>
                  <PriceWrap>
                    <Price withDiscount={oldPrice && +oldPrice !== 0} price={price} />
                    <SalePrice price={oldPrice} />
                  </PriceWrap>
                  <DeleteButton
                    onClick={() => onDelete(id)}
                  >
                    <TrashIcon />
                  </DeleteButton>
                </Row>
              </ProductRow>
            )
          })}
        </Card>
      )}
    </div>
  )
}

Cart.propTypes = {
  products: PropTypes.array,
  onDelete: PropTypes.func
}

export default Cart
