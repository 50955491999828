import React from 'react'
import ChevDownIcon from 'icons/ChevDown'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const ChevronStyled = styled.div`
  cursor: ${({ active }) => !active && 'pointer'};
  font-size: 15px;
  line-height: 19px;
  color: #2e384c;
  opacity: ${({ active }) => active ? 1 : 0.7};
  user-select: none;
  svg{
    stroke: #2e384c;
    margin-left: 8px;
    transform: rotate(${({ active }) => active ? 0 : 180}deg);
  }
`

const OrderingButton = props => {
  const {
    label,
    active,
    onClick
  } = props

  // Render
  return (
    <ChevronStyled
      onClick={onClick}
      active={active}
    >
      {label}
      <ChevDownIcon />
    </ChevronStyled>
  )
}

OrderingButton.propTypes = {
  label: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
}

export default OrderingButton
