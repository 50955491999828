import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import mapIndexed from 'utils/mapIndexed'
import { prop } from 'ramda'
import Link from 'components/Link'
import ChevRight from 'icons/ChevRight'

const BreadcrumbsWrap = styled('div')`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-end;
  font-size: 15px;
  line-height: 19px;
  color: #818591;
  margin-bottom: 23px;
  & > *:not(:last-child) {
    margin: 0 10px 8px 0;
    color: #818591;
    &:hover{
      color: #000;
    }
    svg{
      margin-left: 10px;
      width: 6px;
      height: 11px;
      font-size: 11px;
      margin-bottom: 2px;
      stroke: #9fa2a9;
    }
  }
  & > em {
    font-style: normal;
    cursor: default;
    color: #b8b8b8;
    margin: 0 10px 8px 0;
  }
  ${({ style }) => style};
`

const Breadcrumbs = props => {
  const {
    way = [],
    currentWay,
    style
  } = props

  // List
  const list = mapIndexed((item, index) => {
    if (item) {
      return (
        <Link
          to={prop('url', item)}
          key={index}
        >
          <span>
            {prop('title', item)}
          </span>
          <ChevRight />
        </Link>
      )
    }
    return false
  }, way)

  // Render
  return (
    <BreadcrumbsWrap
      style={style}
    >
      {list}
      <em>{currentWay}</em>
    </BreadcrumbsWrap>
  )
}

Breadcrumbs.propTypes = {
  way: PropTypes.array,
  currentWay: PropTypes.string,
  style: PropTypes.object
}

export default Breadcrumbs
