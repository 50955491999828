import React, { useState } from 'react'
import styled from 'styled-components'
import NewLogo from 'images/NewLogo.jpg'
import { prop, propOr } from 'ramda'
import { useSelector } from 'react-redux'
import * as STATE from 'constants/stateNames'

// Styles
const StyledInstallModal = styled('div')`
  color: black;
  margin: 50px;
  ${({ modalStyles }) => modalStyles};
`
const InstallModalWrapper = styled('div')`
  display: flex;
  align-items: center;
  z-index: 10000;
  justify-content: center;
  position: fixed;
  background: #8bc6ec linear-gradient(135deg, rgba(139,198,236, 0.98) 0%, rgba(106,130,194, 0.98) 100%);
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow-y: auto;
  & ${StyledInstallModal} {
    opacity: ${props => (props.fadeType === 'in' ? '1' : '0')};
    transform: ${props => props.fadeType === 'in' ? 'scale(1)' : 'scale(0.2)'};
    transition:
      transform ${props => `${props.fadeDuration}ms`} cubic-bezier(0.03, 0.93, 0.44, 0.95),
      opacity ${props => `${props.fadeDuration}ms`};
    transform-origin: center 100px;
    max-width: ${({ maxWidth }) => maxWidth};
    width ${props => (props.fullWidth ? '100%' : props.width)};
  }
`
const InstallModalContent = styled('div')`
  background: transparent;
  margin: 50px auto 150px;
  min-width: 300px;
  width: 65vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  position: relative;
  pointer-events: auto;
  p {
    margin: 0 0 15px;
    line-height: 1.6;
    font-size: 32px;
    text-align: center;
  }
`
const NavLink = styled('a')`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.white};
  font-weight: 600;
  &:active, &:hover{
    color: unset;
    text-decoration: underline;
  }
`
const Buttons = styled('div')`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 15px;
  & > a{
    max-width: 90%;
    &:not(:last-child){
      margin-bottom: 25px;
    }
  }
`
const LinkButton = styled('a')`
  display: block;
  cursor: pointer;
  border: 1px solid transparent;
  padding: 20px 20px;
  border-radius: 10px;
  width: 100%;
  background: ${({ active }) => (active ? 'white' : 'transparent')};
  color: ${({ active }) => (active ? '#000' : '#fff')};
  text-align: center;
  font-weight: 600;
  user-select: none;
  line-height: 1.4;
  font-size: 32px;
  &:hover {
    color: ${({ active }) => (active ? '#000' : '#fff')};
  }
  &:active {
    background: white;
    color: #000;
  }
  &:focus {
    background: white;
    text-decoration: none;
    color: #000;
  }
`
const LogoIcon = styled('div')`
  border-radius: 100%;
  width: 350px;
  height: 350px;
  background: #fff url(${NewLogo}) no-repeat center;
  background-size: cover;
  margin: 15px 0 30px;
  & img {
    width: 100%;
    height: 100%;
  }
`

// Component
const InstallModal = () => {
  // useState
  const [open, setOpen] = useState(true)

  // Data
  const config = useSelector(prop(STATE.CONFIG))
  const configData = prop('data', config)

  // Check platforms
  const isAndroid = navigator.userAgent.match(/Android|IEMobile|Opera Mini|BlackBerry/i) && open
  const isIOS = navigator.userAgent.match(/iPhone|iPad|iPod/i) && open

  // Const
  const telegramBotLink = propOr('#', 'tELEGRAMBOTLINK', configData)
  const playMarketLink = propOr('#', 'pLAYMARKETLINK', configData)
  const appStoreLink = propOr('#', 'aPPSTORELINK', configData)
  const showAppStoreLink = propOr(false, 'sHOWAPPSTORELINK', configData)
  const showPlayMarketLink = propOr(false, 'sHOWPLAYMARKETLINK', configData)

  // Render
  if (isAndroid || isIOS) {
    return (
      <InstallModalWrapper>
        <InstallModalContent>
          <LogoIcon />
          <p>
            Для Вашего удобства рекомендуем оформить заказ через<br />
            {((isAndroid && showPlayMarketLink) || (isIOS && showAppStoreLink)) && (
              <>
                <NavLink target={'_blank'} href={isIOS ? appStoreLink : isAndroid ? playMarketLink : '#'}>мобильное приложение</NavLink>
                <span> или </span>
              </>
            )}
            <NavLink target={'_blank'} href={telegramBotLink}>Telegram</NavLink>
          </p>
          <Buttons>
            {isAndroid && showPlayMarketLink
              ? (
                <LinkButton
                  href={playMarketLink}
                  target={'_blank'}
                  active={true}
                >
                  Мобильное приложение
                </LinkButton>
              ) : isIOS && showAppStoreLink
                ? (
                  <LinkButton
                    href={appStoreLink}
                    target={'_blank'}
                    active={true}
                  >
                  Мобильное приложение
                  </LinkButton>
                ) : null}
            <LinkButton
              href={telegramBotLink}
              target={'_blank'}
              active={true}
            >
              Telegram БОТ
            </LinkButton>
            <LinkButton onClick={() => setOpen(false)}>
              Продолжить на сайте
            </LinkButton>
          </Buttons>
        </InstallModalContent>
      </InstallModalWrapper>
    )
  }
  return false
}

InstallModal.propTypes = {}

InstallModal.defaultProps = {}

export default InstallModal
