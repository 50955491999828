import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const DropdownContent = styled.div`
  display: none;
  position: absolute;
  left: -30px;
  top: 72px;
  background: #fff;
  color: #242f3b;
  border-radius: 5px;
  min-width: 260px;
  box-shadow: ${({ theme }) => theme.boxShadow.modal};
  padding: 18px 20px 8px;
  z-index: 100;
  cursor: default;
  & > hr {
    background: #e7e8ea;
    height: 1px;
    border: none;
    margin: 18px 0;
  }
`
const DropdownStyled = styled.div`
  position: relative;
  display: inline-block;
  height: 74px;
  &:hover ${DropdownContent} {
    display: block;
    transition: opacity 2s;
  }
`
const StyledTitle = styled.div`
  margin-top: 25px;
  color: #fff;
`

const Dropdown = (props) => {
  const { title, children } = props

  // Render
  return (
    <DropdownStyled>
      <StyledTitle>
        {title}
      </StyledTitle>
      <DropdownContent>
        {children}
      </DropdownContent>
    </DropdownStyled>
  )
}

Dropdown.propTypes = {
  title: PropTypes.any,
  children: PropTypes.any
}

export default Dropdown
