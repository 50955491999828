import React from 'react'
import styled from 'styled-components'
import { path } from 'ramda'
import NoImage from 'images/NoImage.png'
import numberFormat from 'utils/numberFormat'

const ProductDetail = styled.div`
  display: flex;
  padding: 20px 0;
  border-bottom: 1px solid #E7E8EA;
  &:nth-last-child(1) {
    border-bottom: none;
  }
  &:nth-child(1) {
    padding-top: 0;
  }
`
const ImgWrap = styled('div')`
  min-width: 60px;
  min-height: 60px;
  max-width: 60px;
  max-height: 60px;
  margin-right: 26px;
`
const ProductImage = styled.img`
  width: 100%;
  height: 100%;
`
const ProductDescription = styled.div`

`
const ProductName = styled.div`
  font-weight: 500;
  color: #242f3b;
`
const ProductAmount = styled.div`
  display: flex;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 129.96%;
  color: #818591;
  mix-blend-mode: normal;
  margin-top: 6px;
`
const AmountBlock = styled.div`
`
const PriceBlock = styled.div`
  margin-left: 27px;
`
const MyOrderProducts = props => {
  const { productItem } = props

  return (
    <div>
      {productItem.map((productDetail, key1) => {
        const productName = path(['product', 'name'], productDetail)
        const productAmount = path(['amount'], productDetail)
        const productPrice = path(['price'], productDetail)
        const image = path(['product', 'image', 'file'], productDetail)
        return (
          <ProductDetail key={key1}>
            <ImgWrap>
              <ProductImage src={image || NoImage} alt="Img" />
            </ImgWrap>
            <ProductDescription>
              <ProductName>
                {productName}
              </ProductName>
              <ProductAmount>
                <AmountBlock>
                  {numberFormat(productAmount, 'шт. х')}
                </AmountBlock>
                <PriceBlock>
                  {numberFormat(productPrice, 'сум')}
                </PriceBlock>
              </ProductAmount>
            </ProductDescription>
          </ProductDetail>
        )
      })}
    </div>
  )
}

export default MyOrderProducts
