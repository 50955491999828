import React from 'react'
import SvgIcon from './SvgIcon'

export default props => {
  return (
    <SvgIcon width={34} height={34} viewBox={'0 0 34 34'} {...props}>
      <path d="M11.5621 26.5859L6.48311 29.3791C6.18506 29.543 5.9172 29.7444 5.66797 29.9639C8.62919 32.4813 12.4518 34 16.6283 34C20.774 34 24.5722 32.5039 27.5251 30.0209C27.2528 29.7891 26.9573 29.5805 26.6297 29.416L21.1909 26.6746C20.4882 26.3203 20.0443 25.5963 20.0443 24.8042V22.6528C20.1972 22.4773 20.3719 22.2519 20.5589 21.9857C21.3001 20.9301 21.8609 19.7689 22.2495 18.5507C22.9471 18.3337 23.461 17.6841 23.461 16.9122V14.6157C23.461 14.1105 23.2381 13.6591 22.8919 13.3431V10.0233C22.8919 10.0233 23.5683 4.85712 16.629 4.85712C9.6897 4.85712 10.3661 10.0233 10.3661 10.0233V13.3431C10.0192 13.6591 9.79698 14.1105 9.79698 14.6157V16.9122C9.79698 17.5171 10.1124 18.0494 10.5845 18.357C11.1536 20.855 12.6439 22.6528 12.6439 22.6528V24.7511C12.6432 25.5153 12.2283 26.2193 11.5621 26.5859Z" fill="white" />
      <path opacity="0.403013" d="M17.2912 0.00252272C7.90367 -0.157299 0.162917 7.29676 0.00253327 16.6514C-0.0885646 21.9556 2.28383 26.7227 6.06118 29.8929C6.30817 29.6781 6.57313 29.4812 6.86759 29.3214L11.9402 26.5642C12.6055 26.2023 13.0199 25.5074 13.0199 24.7518V22.6805C13.0199 22.6805 11.5309 20.9059 10.9631 18.4401C10.4923 18.1365 10.1766 17.6116 10.1766 17.0139V14.747C10.1766 14.2483 10.3992 13.8028 10.745 13.4908V10.2138C10.745 10.2138 10.0695 5.11424 17 5.11424C23.9305 5.11424 23.2549 10.2138 23.2549 10.2138V13.4908C23.6014 13.8028 23.8233 14.2483 23.8233 14.747V17.0139C23.8233 17.7759 23.3101 18.4171 22.6134 18.6313C22.2253 19.8338 21.6652 20.98 20.9249 22.0221C20.7382 22.2848 20.5637 22.5073 20.411 22.6805V24.8042C20.411 25.5861 20.8543 26.3014 21.5562 26.6505L26.988 29.3566C27.3139 29.5189 27.6084 29.7242 27.8798 29.9524C31.5429 26.9081 33.9095 22.3564 33.9974 17.2306C34.1591 7.87596 26.6794 0.162344 17.2912 0.00252272Z" fill="#556080" />
    </SvgIcon>
  )
}
