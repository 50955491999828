import React from 'react'
import Layout from 'components/Layout'
import ProductContainer from './ProductContainer'
import { getProduct } from './actions'
import { path, propOr } from 'ramda'

// eslint-disable-next-line func-style
async function action (props) {
  const { store, params } = props

  await store.dispatch(getProduct(params.id))

  const state = store.getState()
  const productItemData = path(['productItem', 'data'], state)
  const title = propOr('Продукт', 'name', productItemData)

  return {
    title,
    component: (
      <Layout {...props} home={true}>
        <ProductContainer id={Number(params.id)} />
      </Layout>
    )
  }
}

export default action
