import React from 'react'
import Layout from 'components/Layout'
import CategoriesContainer from './CategoriesContainer'
import { find, pathOr, propEq, propOr } from 'ramda'

// eslint-disable-next-line require-await,func-style
async function action (props) {
  const { params, store } = props
  const { id } = params

  const state = store.getState()
  const menuAsData = pathOr([], ['menuAs', 'data', 'results'], state)
  const currentCategory = find(propEq('id', +id), menuAsData)
  const title = propOr('Категория', 'name', currentCategory)

  return {
    title,
    component: (
      <Layout {...props} home={true}>
        <CategoriesContainer {...params} />
      </Layout>
    )
  }
}

export default action
