import styled from 'styled-components'

const SectionTitle = styled.div`
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 164.57%;
  color: #2e384c;
  margin-bottom: 22px;
`
export default SectionTitle
