import React from 'react'
import styled from 'styled-components'
import { pathOr, path } from 'ramda'
import { Row, Col } from 'components/Grid'
import MyOrderProducts from './components/MyOrderProducts'
import MyOrderDetails from './components/MyOrderDetails'
import { DELIVERY_STATUS } from 'constants/backend'
import { Check, X } from 'react-feather'

const MyOrders = styled.div`
  background: #FFFFFF;
  border: 1px solid #EAEAEC;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 15px 30px;
  width: 1150px;
  margin-bottom: 32px;
`
const OrderHeader = styled.div`
  display: flex;
  justify-content: space-between;
`
const OrderId = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 164.57%;
  color: ${({ theme }) => theme.palette.primary};
`
const OrderStatus = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 164.57%;
  text-align: right;
  color: #2e384c;
`
const StatusWrap = styled('div')`
  width: 17px;
  height: 17px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  margin-bottom: 2px;
  background: ${({ status, theme }) => status === '4'
    ? theme.palette.red
    : status === '0' || status === '5'
      ? theme.palette.blue
      : theme.palette.green};
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  line-height: 1;
  svg{
    color: #fff;
    width: 12px;
    height: 12px;
    margin-top: 1px;
    stroke-width: 4px;
  }
`
const Line = styled.div`
  border-bottom: 1px solid #EAEAEC;
  margin: 17px  0 35px 0;
`
const MyOrderList = (props) => {
  const { myOrderList } = props
  const data = pathOr([], ['data'], myOrderList)
  return (
    <div>
      {data.map((item, key) => {
        const productItem = pathOr([], ['products'], item)
        const orderId = path(['id'], item)
        const status = path(['status'], item)
        const statusIcon = () => {
          switch (status) {
          case '0': return '?'
          case '1': return <Check />
          case '2': return <Check />
          case '3': return <Check />
          case '4': return <X />
          case '5': return '?'
          default: return '?'
          }
        }

        return (
          <MyOrders key={key}>
            <OrderHeader>
              <OrderId>Заказ № {orderId}</OrderId>
              <OrderStatus>
                <StatusWrap
                  status={status}
                >
                  {statusIcon()}
                </StatusWrap>
                {DELIVERY_STATUS[status]}
              </OrderStatus>
            </OrderHeader>
            <Line />
            <Row>
              <Col span={10}>
                <MyOrderProducts productItem={productItem} />
              </Col>
              <Col span={2} />
              <Col span={12}>
                <MyOrderDetails item={item} />
              </Col>
            </Row>
          </MyOrders>
        )
      })}
    </div>
  )
}

export default MyOrderList
