import * as STATE from 'constants/stateNames'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import equals from 'fast-deep-equal'
import { getDataFromState } from 'utils/get'
import { isEmpty, pathOr } from 'ramda'
import Cart from './Cart'
import { removeItemFrom } from './actions'

const AddressContainer = () => {
  const dispatch = useDispatch()
  const cartList = useSelector(getDataFromState(STATE.CART), equals)

  const onDelete = (id) => {
    return dispatch(removeItemFrom(id))
  }

  const products = pathOr([], ['data'], cartList)
  const productsArr = !products || isEmpty(products) ? [] : products

  const token = useSelector(getDataFromState('login'), equals)

  return (
    <Cart
      products={productsArr}
      onDelete={onDelete}
      token={token}
    />
  )
}

export default AddressContainer
