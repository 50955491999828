import styled from 'styled-components'

const SecondaryButton = styled.button.attrs(props => ({
  type: props.type || 'button',
}))`
  background: #eaebed;
  border-radius: 4px;
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
  border: 0;
  width: 100%;
  cursor: pointer;
  outline: 0;
  min-height: 46px;
  padding: 5px;
`
export default SecondaryButton
