import React from 'react'

export default () => {
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path d="M9 18.5C10.2294 18.5 11.3868 18.2647 12.4721 17.7941C13.5574 17.3235 14.5147 16.675 15.3441 15.8485C16.1735 15.0221 16.8235 14.0647 17.2941 12.9765C17.7647 11.8882 18 10.7294 18 9.5C18 8.27059 17.7647 7.11324 17.2941 6.02794C16.8235 4.94265 16.1735 3.98529 15.3441 3.15588C14.5147 2.32647 13.5559 1.67647 12.4677 1.20588C11.3794 0.735294 10.2206 0.5 8.99118 0.5C7.76177 0.5 6.60441 0.735294 5.51912 1.20588C4.43382 1.67647 3.47794 2.32647 2.65147 3.15588C1.825 3.9853 1.17647 4.94265 0.705883 6.02794C0.235294 7.11324 0 8.27059 0 9.5C0 10.7294 0.235294 11.8882 0.705883 12.9765C1.17647 14.0647 1.82647 15.0221 2.65589 15.8485C3.4853 16.675 4.44265 17.3235 5.52794 17.7941C6.61324 18.2647 7.77059 18.5 9 18.5ZM5.83236 13.4176C5.62647 13.4176 5.45147 13.3441 5.30736 13.1971C5.16324 13.05 5.09118 12.8735 5.09118 12.6676C5.09118 12.4618 5.16471 12.2912 5.31177 12.1559L7.95 9.50883L5.31177 6.86177C5.16471 6.72647 5.09118 6.55589 5.09118 6.35C5.09118 6.14412 5.16324 5.97059 5.30736 5.82941C5.45147 5.68824 5.62647 5.61765 5.83236 5.61765C6.03824 5.61765 6.20883 5.69118 6.34412 5.83824L8.99118 8.47647L11.6559 5.82942C11.7971 5.67647 11.9676 5.6 12.1676 5.6C12.3735 5.6 12.5485 5.67206 12.6926 5.81618C12.8368 5.9603 12.9088 6.1353 12.9088 6.34118C12.9088 6.54706 12.8353 6.71765 12.6882 6.85294L10.0412 9.50883L12.6794 12.1382C12.8265 12.2971 12.9 12.4735 12.9 12.6677C12.9 12.8735 12.8279 13.05 12.6838 13.1971C12.5397 13.3441 12.3647 13.4177 12.1588 13.4177C11.9588 13.4177 11.7824 13.3441 11.6294 13.1971L8.99118 10.55L6.36177 13.1971C6.22059 13.3441 6.04412 13.4177 5.83236 13.4177V13.4176Z" fill="#B4B7BE" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect y="0.5" width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
