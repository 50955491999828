import { arrayObjToObj } from 'utils/get'

export const PAYMENT_TYPE_LIST = [
  {
    id: 'payme',
    name: 'Онлайн оплата через Payme',
  },
  {
    id: 'click',
    name: 'Онлайн оплата через Click',
  },
  {
    id: 'cash',
    name: 'Наличными (при получении)'
  },
  {
    id: 'bank',
    name: 'Терминал (при получении)'
  }
]

export const DELIVERY_STATUS_LIST = [
  {
    id: '0',
    name: 'В ожидании',
  },
  {
    id: '1',
    name: 'Готов',
  },
  {
    id: '2',
    name: 'Отдан',
  },
  {
    id: '3',
    name: 'Доставлен',
  },
  {
    id: '4',
    name: 'Отменён',
  },
  {
    id: '5',
    name: 'Не подтверждён',
  }
]

export const DELIVERY_STATUS = arrayObjToObj(DELIVERY_STATUS_LIST)

export const PAYMENT_TYPE = arrayObjToObj(PAYMENT_TYPE_LIST)
