import React, { useState } from 'react'
import styled from 'styled-components'
import Preloader from 'components/UI/PreLoader'
import useCompareEffect from 'hooks/useCompareEffect'
import Container from 'components/StyledElems/Container'
import TextField from 'components/Forms/TextField'
import Timer from './components/Timer'
import Button from 'components/Forms/Button'
import { prop } from 'ramda'

const ContainerUI = styled(Container)`
  padding: 35px 15px 40px;
`
const Title = styled('h1')`
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  color: #2e384c;
  margin: 0 0 35px;
`
const FormWrapper = styled('div')`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 615px;
  & > *:not(:last-child){
    margin-bottom: 20px;
  }
`
const TimerWrap = styled('div')`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`
const LoadWrap = styled('div')`
  width: 100%;
  margin: 20px 0;
  & > div{
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
`
const TimerText = styled('div')`
  font-size: 14px;
  line-height: 18px;
  color: #818591;
`
const RejectText = styled('div')`
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.palette.red};
  font-weight: 500;
  &:after{
    content: ' *';
  }
`

const SignIn = props => {
  const {
    onRegister,
    onLogin,
    registerData,
    loginData
  } = props

  // useState
  const [openLogin, setOpenLogin] = useState(false)
  const [form, setValues] = useState({
    phoneNumber: '',
    code: ''
  })

  // Const
  const loadingRegister = registerData.loading
  const loadingLogin = loginData.loading
  const isLoading = loadingRegister || loadingLogin
  const loginFailed = prop('failed', loginData)

  // Loader
  const loader =
    <LoadWrap>
      <Preloader />
    </LoadWrap>

  const updateField = (event, num) => {
    setValues({
      ...form,
      [event.target.name]: event.target.value
    })
    if (event.target.value >= num) {
      event.target.value = event.target.value.slice(0, num)
    }
  }

  useCompareEffect(() => {
    if (form.phoneNumber.length === 9) {
      onRegister(form.phoneNumber).then(() => setOpenLogin(true))
    }
  }, [form.phoneNumber])

  // OnSubmit
  const handleSubmit = () => {
    onLogin(form.code, form.phoneNumber)
  }

  // FormContent
  const formContent =
    <form onSubmit={(ev) => ev.preventDefault()}>
      <FormWrapper>
        <TextField
          name={'phoneNumber'}
          prefix={'+998'}
          type={'number'}
          onChange={ev => updateField(ev, 9)}
          label={'Номер телефона'}
          fullWidth={true}
          disabled={isLoading}
        />
        {openLogin && (
          <>
            <TextField
              name={'code'}
              type={'number'}
              onChange={ev => updateField(ev, 5)}
              label={'Код из SMS'}
              fullWidth={true}
              maxLength={5}
              disabled={isLoading}
            />
            <TimerWrap>
              <TimerText>Мы отправили SMS с кодом на ваш номер</TimerText>
              <Timer
                time={60}
                onAfterClick={() => onRegister(form.phoneNumber)}
              />
            </TimerWrap>
            <Button
              text={'Войти'}
              type={'button'}
              onClick={handleSubmit}
              fullWidth={true}
            />
            {loginFailed &&
            <RejectText>
              Ошибка при вводе данных
            </RejectText>}
          </>
        )}
        {(isLoading) && loader}
      </FormWrapper>
    </form>

  // Render
  return (
    <>
      <ContainerUI>
        <Title>
          Авторизация
        </Title>
        {formContent}
      </ContainerUI>
    </>
  )
}

export default SignIn
