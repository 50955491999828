import * as actionTypes from 'constants/actionTypes'
import React, { useContext } from 'react'
import styled from 'styled-components'
import Link from 'components/Link'
import Logo from 'icons/Logo'
import { userSignOut } from 'routes/sign-in/actions'
import { useDispatch } from 'react-redux'
import { SearchField } from 'components/UI/FormField'
import DisplayFlex from 'components/StyledElems/DisplayFlex'
import Cont from 'components/Container'
import History from '../../../HistoryProvider'
import Catalog from './Catalog'
import TopHeader from './TopHeader'
import AuthMenu from './AuthMenu'
import PropTypes from 'prop-types'
import { isEmpty, pathOr, takeLast } from 'ramda'
import CartDropdown from 'components/Cart/CartDropdown'

const HeaderBlock = styled('div')`
  position: fixed;
  width: 100%;
  z-index: 20;
  top: 0;
  left: 0;
  right: 0;
  background-color: ${({ theme }) => theme.palette.primary};
`
const Container = styled(Cont)`
  position: relative;
`
const LogoLink = styled(Link)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 74px;
`

const Header = (props) => {
  const {
    isAuth,
    products = [],
    routes
  } = props

  // Const
  const history = useContext(History)
  const dispatch = useDispatch()

  const productsLength = products.length

  let sumall = 0
  const totalPr = !isEmpty(products) && products.map((product) => {
    const productPrice = Number(pathOr(0, ['price'], product))
    const amount = pathOr(0, ['amount'], product)
    const totalProdPrice = productPrice * amount
    sumall += totalProdPrice
    return sumall
  })
  const totalPrice = takeLast(1, totalPr)

  // Handlers
  const onSignOut = () => {
    return dispatch(userSignOut())
      .then(() => history.replace('/'))
      .then(() => {
        dispatch({ type: `${actionTypes.LOGIN}_CLEAR` })
        dispatch({ type: `${actionTypes.USER_INFO}_CLEAR` })
      })
  }

  return (
    <HeaderBlock>
      <TopHeader />
      <Container>
        <DisplayFlex
          alignItems="center"
        >
          <LogoLink to="/">
            <Logo />
          </LogoLink>
          <Catalog />
          <SearchField
            routes={routes}
          />
          <AuthMenu
            isAuth={isAuth}
            onSignOut={onSignOut}
          />
          <CartDropdown
            totalPrice={totalPrice}
            productsLength={productsLength}
            products={products}
          />
        </DisplayFlex>
      </Container>
    </HeaderBlock>
  )
}

Header.propTypes = {
  isAuth: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ]),
}

export default Header
