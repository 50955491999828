import React from 'react'
import SvgIcon from './SvgIcon'

export default props => {
  return (
    <SvgIcon
      fontSize="unset"
      viewBox="0 0 18 18"
      width="20"
      height="20"
      fill="none"
      {...props}
    >
      <path d="M15.6306 3.4575C15.2475 3.07425 14.7927 2.77023 14.2921 2.56281C13.7915 2.35539 13.2549 2.24863 12.7131 2.24863C12.1712 2.24863 11.6347 2.35539 11.1341 2.56281C10.6335 2.77023 10.1786 3.07425 9.79558 3.4575L9.00058 4.2525L8.20558 3.4575C7.43181 2.68373 6.38235 2.24903 5.28808 2.24903C4.1938 2.24903 3.14435 2.68373 2.37058 3.4575C1.59681 4.23127 1.16211 5.28072 1.16211 6.375C1.16211 7.46927 1.59681 8.51873 2.37058 9.2925L3.16558 10.0875L9.00058 15.9225L14.8356 10.0875L15.6306 9.2925C16.0138 8.90943 16.3178 8.45461 16.5253 7.95401C16.7327 7.45342 16.8394 6.91686 16.8394 6.375C16.8394 5.83313 16.7327 5.29657 16.5253 4.79598C16.3178 4.29539 16.0138 3.84056 15.6306 3.4575V3.4575Z" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
  )
}
