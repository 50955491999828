import styled from 'styled-components'

const Container = styled.div`
    margin: 0 auto;
    width: 1170px;
    max-width: 100%;
    padding: 0 15px;
`

export default Container
