import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Wrap = styled('button')`
  padding: 5px 10px;
  margin: 0;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: none;
  letter-spacing: inherit;
  background: ${({ theme }) => theme.palette.primary};
  color: #fff;
  min-height: 50px;
  font-weight: 500;
  width: ${({ fullWidth }) => fullWidth && '100%'};
  cursor: pointer;
  :not([type=radio]){
    -webkit-appearance: none !important;
  }
  ${({ style }) => style};
`

const Button = props => {
  const {
    text,
    type = 'submit',
    onClick,
    fullWidth,
    ref,
    style
  } = props

  // Render
  return (
    <Wrap
      type={type}
      ref={ref}
      onClick={onClick}
      fullWidth={fullWidth}
      style={style}
    >
      {text}
    </Wrap>
  )
}

Button.propTypes = {
  text: PropTypes.any.isRequired,
  onClick: PropTypes.func,
  fullWidth: PropTypes.bool,
  type: PropTypes.oneOf(['submit', 'button'])
}

export default Button
