import React from 'react'
import styled from 'styled-components'
import { curry, path } from 'ramda'
import numberFormat from 'utils/numberFormat'
import PropTypes from 'prop-types'

const Wrapper = styled.div`
  margin-left: -10px;
  margin-bottom: -5px;
  display: flex;
  flex-wrap: wrap;
`
const Block = styled.div`
  background: #fff;
  width: calc(50% - 20px);
  margin: 0 7.5px 15px 10px;
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme, isActive }) => isActive ? theme.palette.primary : '#dbdbdd'};
  border-radius: 7px;
  cursor: pointer;
  padding: 10px 20px;
  min-height: 87px;
  vertical-align: top;
`
const TitleFlex = styled.div`
  display: flex;
  justify-content: space-between;
`
const Title = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.67;
`
const Prise = styled.div`
  color: ${({ theme }) => theme.palette.primary};
  font-size: 15px;
  font-weight: 500;
  line-height: 1.67;
`
const SubTitle = styled.div`
  color: #818591;
  margin: 3px 0 20px 0;
  font-size: 14px;
  line-height: 20px;
`

const BottomTexts = styled.div`
  margin: auto 0 0 0;
`

const Free = styled.div``

const EMPTY_OBJ = {}

const OrderSelectField = props => {
  const {
    input,
    data
  } = props
  const onChange = curry(value => input.onChange(value))

  return (
    <Wrapper>
      {data.map(item => {
        const isActive = input.value && input.value.id === item.id
        const toggleItem = isActive ? EMPTY_OBJ : item
        const price = path(['cost'], item)
        const priceText = (+price > 0) ? numberFormat(price, 'сум') : <Free>Бесплатно</Free>
        const icon = path(['icon'], item)
        return (
          <Block
            isActive={isActive}
            key={item.id}
            onClick={() => onChange(toggleItem)}
          >
            {icon && icon.length > 0 ? <img src={icon} alt="icon" /> : null}
            <TitleFlex>
              <Title>{item.name}</Title>
            </TitleFlex>
            <SubTitle>
              {item.info || item.description}
            </SubTitle>
            <BottomTexts>
              <Prise>{priceText}</Prise>
            </BottomTexts>
          </Block>
        )
      })}
    </Wrapper>
  )
}

OrderSelectField.propTypes = {
  input: PropTypes.object,
  data: PropTypes.any
}

export default OrderSelectField
