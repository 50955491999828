import PropTypes from 'prop-types'
import styled from 'styled-components'

const ButtonStyled = styled('button')`
  background: ${({ theme }) => theme.palette.primary};
  color: ${({ theme }) => theme.palette.white};
  border-radius: 4px;
  height: 50px;
  width: ${({ fullWidth }) => fullWidth ? '100%' : '200px'};
  border: none;
  outline: 0;
  cursor: pointer;
  //margin-top: 10px;
  font-weight: 600;
`

ButtonStyled.propTypes = {
  children: PropTypes.node
}

export default ButtonStyled
