export const USER_INFO = 'USER_INFO'
export const USER_RESET_PASSWORD = 'USER_RESET_PASSWORD'
export const LANGUAGE = 'LANGUAGE'
export const CART_LIST = 'CART_LIST'

export const CONFIG = 'CONFIG'

export const STATIC_PAGES = 'STATIC_PAGES'
export const STATIC_PAGES_ITEM = 'STATIC_PAGES_ITEM'
export const ACTIVE_USERS_COUNT = 'ACTIVE_USERS_COUNT'
export const NOTIFICATIONS = 'NOTIFICATIONS'

export const GENERAL_STATS = 'GENERAL_STATS'

export const PROFESSIONS_LIST = 'PROFESSIONS_LIST'
export const REGIONS_LIST = 'REGIONS_LIST'
export const USER_ACTIVATION = 'USER_ACTIVATION'
export const SPECIALITY_LIST = 'SPECIALITY_LIST'
export const SPECIALITY_LIST_ALL = 'SPECIALITY_LIST_ALL'
export const INSTITUTION_LIST = 'INSTITUTION_LIST'
export const INSTITUTION_CREATE = 'INSTITUTION_CREATE'
export const SEARCH_LIST = 'SEARCH_LIST'
export const SEARCH_HISTORY = 'SEARCH_HISTORY'
export const SEARCH_HISTORY_CREATE = 'SEARCH_HISTORY_CREATE'
export const DRIVER_LICENSE_LIST = 'DRIVER_LICENSE_LIST'
export const CURRENCY_LIST = 'CURRENCY_LIST'
export const COUNTRY_LIST = 'COUNTRY_LIST'
export const LANGUAGE_LIST = 'LANGUAGE_LIST'

export const EMPLOYER_LIST = 'EMPLOYER_LIST'
export const EMPLOYER_ITEM = 'EMPLOYER_ITEM'
export const EMPLOYER_FAVORITE = 'EMPLOYER_FAVORITE'
export const STAT_EMPLOYER_GENERAL = 'STAT_EMPLOYER_GENERAL'
export const STAT_EMPLOYER_VIEWS = 'STAT_EMPLOYER_VIEWS'
export const STAT_EMPLOYER_VACANCY_VIEWS = 'STAT_EMPLOYER_VACANCY_VIEWS'
export const EMPLOYER_GUESTS = 'EMPLOYER_GUESTS'

export const EMPLOYER_STAFF_LIST = 'EMPLOYER_STAFF_LIST'
export const EMPLOYER_STAFF_ITEM = 'EMPLOYER_STAFF_ITEM'
export const EMPLOYER_STAFF_UPDATE = 'EMPLOYER_STAFF_UPDATE'
export const EMPLOYER_STAFF_DELETE = 'EMPLOYER_STAFF_DELETE'
export const EMPLOYER_STAFF_CREATE = 'EMPLOYER_STAFF_CREATE'

export const APPLICANT_LIST = 'APPLICANT_LIST'
export const APPLICANT_ITEM = 'APPLICANT_ITEM'
export const APPLICANT_UPDATE = 'APPLICANT_UPDATE'
export const APPLICANT_FAV_VACANCY_LIST = 'APPLICANT_FAV_VACANCY_LIST'
export const APPLICANT_FAV_EMPLOYERS_LIST = 'APPLICANT_FAV_EMPLOYERS_LIST'
export const APPLICANT_RATE = 'APPLICANT_RATE'

export const VACANCY_APPROVED_LIST = 'VACANCY_APPROVED_LIST'
export const VACANCY_APPROVED_ITEM = 'VACANCY_APPROVED_ITEM'
export const VACANCY_CREATE = 'VACANCY_CREATE'
export const VACANCY_UPDATE = 'VACANCY_UPDATE'
export const VACANCY_DELETE = 'VACANCY_DELETE'
export const VACANCY_ACTIVATE = 'VACANCY_ACTIVATE'
export const VACANCY_DEACTIVATE = 'VACANCY_DEACTIVATE'
export const VACANCY_POPULAR_LIST = 'VACANCY_POPULAR_LIST'
export const VACANCY_APPEALED_LIST = 'VACANCY_APPEALED_LIST'
export const VACANCY_APPEALED_ITEM = 'VACANCY_APPEALED_ITEM'
export const VACANCY_APPEALED_ITEM_COUNT = 'VACANCY_APPEALED_ITEM_COUNT'
export const VACANCY_APPEALED_ITEM_STATUS_CHANGE = 'VACANCY_APPEALED_ITEM_STATUS_CHANGE'
export const VACANCY_FAV_CREATE = 'VACANCY_FAV_CREATE'
export const VACANCY_ITEM_VIEW_HISTORY = 'VACANCY_ITEM_VIEW_HISTORY'

export const VACANCY_APPEAL_LIST = 'VACANCY_APPEAL_LIST'
export const VACANCY_APPEAL_CREATE = 'VACANCY_APPEAL_CREATE'
export const VACANCY_APPEAL_ITEM = 'VACANCY_APPEAL_ITEM'

export const EMPLOYER_APPEAL_CREATE = 'EMPLOYER_APPEAL_CREATE'
export const EMPLOYER_APPEAL_LIST = 'EMPLOYER_APPEAL_LIST'

export const EMPLOYER_MANAGER_CHAT = 'EMPLOYER_MANAGER_CHAT'
export const EMPLOYER_APPEAL_CHAT = 'EMPLOYER_APPEAL_CHAT'
export const FRONT_CHAT_LIST = 'FRONT_CHAT_LIST'
export const CHAT_MESSAGES = 'CHAT_MESSAGES'

export const LOGIN = 'LOGIN'
export const REGISTER = 'REGISTER'
export const ASYNC_LOADING = 'ASYNC_LOADING'
export const NOTIFY_OPEN = 'NOTIFY_OPEN'
export const CHAT_DIALOG_OPEN = 'CHAT_DIALOG_OPEN'

export const RESUME_DEACTIVATE = 'RESUME_DEACTIVATE'
export const RESUME_ACTIVATE = 'RESUME_ACTIVATE'

export const RESUME_ACTIVE_LIST = 'RESUME_ACTIVE_LIST'
export const RESUME_ACTIVE_ITEM = 'RESUME_ACTIVE_ITEM'
export const RESUME_ITEM_UPDATE_DATE = 'RESUME_ITEM_UPDATE_DATE'
export const RESUME_LIST = 'RESUME_LIST'
export const RESUME_FAV_LIST = 'RESUME_FAV_LIST'
export const RESUME_FAV_CREATE = 'RESUME_FAV_CREATE'
export const RESUME_CREATE = 'RESUME_CREATE'
export const RESUME_UPDATE = 'RESUME_UPDATE'
export const RESUME_ITEM = 'RESUME_ITEM'
export const RESUME_DELETE = 'RESUME_DELETE'
export const RESUME_ITEM_GUESTS = 'RESUME_ITEM_GUESTS'
export const RESUME_SUITABLE_LIST = 'RESUME_SUITABLE_LIST'
export const RESUME_INVITE = 'RESUME_INVITE'
export const RESUME_DOWNLOAD = 'RESUME_DOWNLOAD'

export const ARTICLE_LIST = 'ARTICLE_LIST'
export const ARTICLE_ITEM = 'ARTICLE_ITEM'

export const EMPLOYER_SERVICES_LIST = 'EMPLOYER_SERVICES_LIST'
export const EMPLOYER_ORDERS_LIST = 'EMPLOYER_ORDERS_LIST'
export const EMPLOYER_ORDERS_ITEM = 'EMPLOYER_ORDERS_ITEM'

export const FAQ_LIST = 'FAQ_LIST'

export const EMPLOYER_SERVICE_LIST = 'EMPLOYER_SERVICE_LIST'
export const VACNACY_SERVICE_DISCOUNTS = 'VACNACY_SERVICE_DISCOUNTS'
export const DATABASE_ACCESS_LIST = 'DATABASE_ACCESS_LIST'
export const DATABASE_ACCESS_ITEM = 'DATABASE_ACCESS_ITEM'
export const VIP_PACKET_LIST = 'VIP_PACKET_LIST'
export const FILL_BALANCE = 'FILL_BALANCE'
export const APPLICANT_SERVICE_LIST = 'APPLICANT_SERVICE_LIST'
export const SERVICES_ACTIVATE = 'SERVICES_ACTIVATE'
export const SERVICES_MIN_PRICES = 'SERVICES_MIN_PRICES'

export const USER_ACTIVE_SERVICES = 'USER_ACTIVE_SERVICES'
export const USER_UNUSED_SERVICES = 'USER_UNUSED_SERVICES'
export const USER_BLOCK = 'USER_BLOCK'

export const ORDER_CREATE = 'ORDER_CREATE'
export const ORDER_LIST = 'ORDER_LIST'
export const ORDER_ITEM = 'ORDER_ITEM'
export const ORDER_MAKE_PAYMENT = 'ORDER_MAKE_PAYMENT'
export const ORDER_ACTIVATE = 'ORDER_ACTIVATE'
export const ORDER_REQUEST_CONTRACT = 'ORDER_REQUEST_CONTRACT'

export const FEEDBACK_CREATE = 'FEEDBACK_CREATE'

export const CART_CHANGE_LIST = 'CART_CHANGE_LIST'

export const PRODUCT_LIST = 'PRODUCT_LIST'
export const PRODUCT_LIST_POPULAR = 'PRODUCT_LIST_POPULAR'
export const PRODUCT_LIST_NEW = 'PRODUCT_LIST_NEW'
export const PRODUCT_LIST_MONTH = 'PRODUCT_LIST_MONTH'
export const PRODUCT_CATEGORY_LIST = 'PRODUCT_CATEGORY_LIST'
export const PRODUCT_ITEM = 'PRODUCT_ITEM'

export const CART = 'CART'

export const MENU_AS = 'MENU_AS'

export const SIGN_IN = 'SIGN_IN'

export const DELIVERY = 'DELIVERY'

export const PROFILE = 'PROFILE'
export const ACTIVATE_MAILING = 'ACTIVATE_MAILING'
export const DEACTIVATE_MAILING = 'DEACTIVATE_MAILING'

export const ADDRESS_CREATE = 'ADDRESS_CREATE'
export const ADDRESS_LIST = 'ADDRESS_LIST'
export const ADDRESS_DELETE = 'ADDRESS_DELETE'

export const DELIVERY_TYPE_LIST = 'DELIVERY_TYPE_LIST'

export const FAVOURITE_CREATE = 'FAVOURITE_CREATE'
export const FAVOURITE_LIST = 'FAVOURITE_LIST'
export const FAVOURITE_DELETE = 'FAVOURITE_DELETE'

export const FILTER_LIST = 'FILTER_LIST'

export const COMMENT_CREATE = 'COMMENT_CREATE'
export const COMMENT_LIST = 'COMMENT_LIST'
export const COMMENT_ITEM = 'COMMENT_ITEM'

export const CLIENT_UPDATE = 'CLIENT_UPDATE'

export const FOOTER_PAGES = 'FOOTER_PAGES'
export const FOOTER_PAGES_DETAIL = 'FOOTER_PAGES_DETAIL'

// Error
export const ERROR = 'ERROR'
export const ERROR_OPEN = `${ERROR}_OPEN`
export const ERROR_CLOSE = `${ERROR}_CLOSE`
