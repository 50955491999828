import React from 'react'
import styled from 'styled-components'
import { map, pathOr, pipe, split, propOr, filter, isEmpty } from 'ramda'
import PropTypes from 'prop-types'
import FilterTags from './FilterTags'
import FilterSection from './FilterSection'

const FilterBlock = styled('div')`
  display: flex;
  flex-flow: column nowrap;
  background: #fff;
  border-radius: 4px;
  width: 267px;
  margin: 35px 0 40px;
`
const NoOptions = styled('div')`
  padding: 15px 20px;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
`

const emptyStr = ''
const getIds = (data, key) => pipe(
  propOr(emptyStr, key),
  split('-'),
  filter(Boolean),
  map(Number)
)(data)

const defArr = []
const Filter = props => {
  const {
    data,
    onChange,
    addChildren,
    queryParams,
    onReset,
    onItemReset
  } = props
  // Const
  const brands = pathOr(defArr, ['brands'], data)
  const countries = pathOr(defArr, ['country'], data)
  const options = pathOr(defArr, ['option'], data)
  const brandIds = getIds(queryParams, 'brand')
  const countryIds = getIds(queryParams, 'country')
  const optionIds = getIds(queryParams, 'option')
  const isEmptyOptions = isEmpty(brands) && isEmpty(countries) && isEmpty(options)

  // Options
  const optionsContent =
    <>
      <FilterTags
        countries={countries}
        options={options}
        brands={brands}
        brandIds={brandIds}
        countryIds={countryIds}
        optionIds={optionIds}
        onReset={onReset}
        onItemReset={onItemReset}
      />
      {!isEmpty(brands) &&
      <FilterSection
        label={'Бренд'}
        queryName={'brand'}
        ids={brandIds}
        list={brands}
        onChange={onChange}
      />}
      {!isEmpty(countries) &&
      <FilterSection
        label={'Страна'}
        queryName={'country'}
        ids={countryIds}
        list={countries}
        onChange={onChange}
      />}
      {options.map((option) => {
        const optionValues = pathOr(defArr, ['optionValues', 'values'], option)
        return (
          <FilterSection
            key={option.id}
            label={option.name}
            queryName={'option'}
            list={optionValues}
            ids={optionIds}
            onChange={onChange}
          />
        )
      })}
    </>

  // Render
  return (
    <FilterBlock>
      {addChildren}
      {!isEmptyOptions
        ? optionsContent
        : <NoOptions>Нет фильтров</NoOptions>}
    </FilterBlock>
  )
}

Filter.propTypes = {
  data: PropTypes.object,
  onChange: PropTypes.func,
  onReset: PropTypes.func,
  onItemReset: PropTypes.func,
  queryParams: PropTypes.object
}
export default Filter
