import React from 'react'
import styled from 'styled-components'
import { path } from 'ramda'
import ProductCardList from 'components/Cards/ProductCardList'
import SectionTitle from 'components/StyledElems/SectionTitle'
import ContainerUI from 'components/Container'
import Carusel from 'components/UI/Carusel'
import ProductsTitle from 'components/UI/ProductsTitle'
import Skeleton from 'components/UI/Skelet'

const Container = styled(ContainerUI)`
  padding: 24px 15px 60px;
`
const ProductListBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px;
  border-radius: 5px;
`
const PopularBlock = styled.div`
  margin-top: 30px;
`
const bannerStyles = {
  margin: '0 0 30px'
}

const Home = props => {
  const {
    productDataPopular,
    productDataNew,
    productDataMonth
  } = props

  const loading = path(['loading'], productDataPopular) || (!path(['data'], productDataPopular) && !path(['failed'], productDataPopular))
  const loadingNew = path(['loading'], productDataNew) || (!path(['data'], productDataNew) && !path(['failed'], productDataNew))
  const loadingMonth = path(['loading'], productDataMonth) || (!path(['data'], productDataMonth) && !path(['failed'], productDataMonth))

  return (
    <Container>
      <Carusel
        section={'top'}
        styles={bannerStyles}
      />

      <SectionTitle>Популярные товары</SectionTitle>
      {!loading
        ? (
          <>
            <ProductsTitle />
            <ProductListBlock>
              <ProductCardList
                productData={productDataPopular}
                column={4}
              />
            </ProductListBlock>
          </>
        )
        : <Skeleton count={9} col={4} />}

      <Carusel
        section={'center'}
        styles={bannerStyles}
      />

      <SectionTitle>Новинки</SectionTitle>
      {loadingNew
        ? <Skeleton count={9} col={4} />
        : (
          <>
            <ProductsTitle />
            <ProductListBlock>
              <ProductCardList
                productData={productDataNew}
                column={4}
              />
            </ProductListBlock>
          </>
        )}

      <Carusel
        section={'bottom'}
        styles={bannerStyles}
      />

      <PopularBlock>
        <SectionTitle>Лучшие товары за месяц</SectionTitle>
      </PopularBlock>
      <ProductsTitle />
      {loadingMonth
        ? <Skeleton count={9} col={4} />
        : (
          <>
            <ProductsTitle />
            <ProductListBlock>
              <ProductCardList
                productData={productDataMonth}
                column={4}
              />
            </ProductListBlock>
          </>
        )}

    </Container>
  )
}

export default Home
