import React from 'react'
import SvgIcon from './SvgIcon'

export default (props) => {
  return (
    <SvgIcon viewBox="0 0 22 22" {...props}>
      <path d="M2.75 11H19.25" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M2.75 5.5H19.25" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M2.75 16.5H19.25" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
  )
}
