import * as API from 'constants/api'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import axios, { getPayloadFromSuccess } from 'utils/axios'
import { useStore } from 'react-redux'
import { defaultTo, isEmpty, pipe, prop, path } from 'ramda'
import PropTypes from 'prop-types'
import Slider from 'react-slick'

const EMPTY_ARR = []

const CaruselStyled = styled.div`
  & .slider-carusel-wrapper{
    position: relative;
    background: ${({ theme }) => theme.palette.primaryLight};
    height: 328px;
    border-radius: 10px;
    width: 100%;
    ${({ styles }) => styles};
  }
  & .slick-list{
    border-radius: 10px;
    overflow: hidden;
    height: 100%;
  }
  & .slick-dots{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 5px;
    & > li {
      width: 9px;
      height: 9px;
      margin: 4px 6px;
      border: 2px solid #fff;
      border-radius: 50%;
      background: #fff;
      transition: background 0.2s ease 0s;
      &.slick-active{
        background: ${({ theme }) => theme.palette.primary};
      }
    }
  }
`
const Item = styled('div')`
  height: 328px;
  width: 100%;
  background: ${({ theme, imgSrc }) => `${theme.palette.primary} url(${imgSrc}) no-repeat center`};
  background-size: cover;
  cursor: pointer;
`

const Carusel = (props) => {
  const {
    section,
    styles
  } = props

  // useState
  const [data, setData] = useState(EMPTY_ARR)
  const [error, setErr] = useState(false)

  const [clickable, setClickable] = useState(true)

  // Const
  const store = useStore()

  // Handlers
  const handleRedirect = link => window
    ? window.open(link, '_blank')
    : null

  // useEffect
  useEffect(() => {
    axios(store)
      .get(API.AD_LIST, { params: { type: section, thumbnail_type: 'original' } })
      .then(getPayloadFromSuccess)
      .then(pipe(prop('results'), defaultTo([]), setData))
      .catch(() => setErr(true))
  }, [section, store])

  if (error || isEmpty(data)) {
    return null
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    easing: 'ease-in-out',
    arrows: false,
    className: 'slider-carusel-wrapper',
    afterChange: () => setClickable(true),
    beforeChange: () => setClickable(false)
  }

  return (
    <CaruselStyled
      styles={styles}
    >
      <Slider
        {...settings}
      >
        {data.map(item => {
          return (
            <Item
              key={path(['image', 'id'], item)}
              imgSrc={path(['image', 'file'], item)}
              onClick={clickable
                ? () => handleRedirect(prop('link', item))
                : null}
            />
          )
        })}
      </Slider>
    </CaruselStyled>
  )
}

Carusel.propTypes = {
  section: PropTypes.string,
  styles: PropTypes.object
}
export default Carusel
