import React from 'react'
import styled from 'styled-components'
import FavoriteIcon from 'icons/Favorite'
import Link from 'components/Link'
import getCookie from 'utils/getCookie'

const TooltipContent = styled.div`
  display: none;
  position: absolute;
  left: -93px;
  top: -42px;
  background: #fff;
  border-radius: 7px;
  min-width: 210px;
  width: auto;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  text-align: center;
  z-index: 1;
  user-select: none;
  & > a{
    display: block;
    padding: 12px 16px;
    color: ${({ theme }) => theme.color.secondary};
    &:hover{
      color: ${({ theme }) => theme.palette.primary};
    }
  }
`
const TooltipStyled = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
  font-weight: 500;
  &:hover ${TooltipContent} {
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.11);
    display: block;
    transition: opasity 2s;
  }
`

const Tooltip = () => {
  const isAuth = !!getCookie('token')

  return (
    <TooltipStyled>
      <FavoriteIcon />
      {!isAuth &&
      <TooltipContent>
        <Link to="/sign-in">
          Требуется регистрация
        </Link>
      </TooltipContent>}
    </TooltipStyled>
  )
}

export default Tooltip
