export const API_HOST = 'api.lochin.uz/en'
export const API_ROOT = 'api'
export const API_VERSION = 'v1'
export const API_PROTOCOL = 'https'
export const API_URL = `${API_PROTOCOL}://${API_HOST}/${API_ROOT}/${API_VERSION}`

export const CHECK_TOKEN = '/e_commerce/users/detail/'
export const CONFIG = 'config'

const MAIN = 'main'
const SERVICE = 'service'

export const STATIC_PAGES = `${MAIN}/static_pages/`
export const STATIC_PAGES_ITEM = `${MAIN}/static_pages/%s/`
export const ARTICLE = `${MAIN}/article`
export const ARTICLE_LIST = `${ARTICLE}/`
export const ARTICLE_CREATE = `${ARTICLE}/`
export const ARTICLE_ITEM = `${ARTICLE}/%d/`

export const LANGUAGE = 'main/language/language'
export const LANGUAGE_LIST = `${LANGUAGE}/`

export const FAQ = `${MAIN}/faq`
export const FAQ_LIST = `${FAQ}/`

export const USER_ACTIVE_SERVICES = `${SERVICE}/active_services/`
export const USER_UNUSED_SERVICES = `${SERVICE}/active_services/unused_services/`

export const LOGIN = 'e_commerce/activate_code/'

export const LOGOUT = 'e_commerce/users/logout/'

export const FILE_UPLOAD = '/main/file/'

export const STAT_EMPLOYER_GENERAL = 'statistics/employer/general/'
export const STAT_EMPLOYER_VIEWS = 'statistics/employer/view_history/'
export const STAT_EMPLOYER_VACANCY_VIEWS = 'statistics/employer/vacancy_view_history/'

const FEEDBACK = `${MAIN}/feedback`
export const FEEDBACK_CREATE = `${FEEDBACK}/`

export const PRODUCT = '/e_commerce/product'
export const PRODUCT_LIST = `${PRODUCT}/`
export const PRODUCT_CATEGORY_LIST = `${PRODUCT}`
export const PRODUCT_ITEM = `${PRODUCT}/%d/`

// if user
export const ORDER = 'e_commerce/order'
export const ORDER_LIST = `${ORDER}/`
export const ORDER_CREATE = `${ORDER}/`
export const ORDER_ITEM = `${ORDER}/%d/`

export const AD = 'e_commerce/banners'
export const AD_LIST = `${AD}/`

export const MENU_AS = 'e_commerce/product_type'

export const REGISTER = 'e_commerce/register/'

export const CLIENT = `${MAIN}/client`
// export const CLIENT_UPDATE = `${CLIENT}/%d/`
export const CLIENT_UPDATE = 'e_commerce/users/update/'
export const CLIENT_DETAIL = `${CLIENT}/%d/`

export const ACTIVATE_MAILING = `${CLIENT}/%d/activate_mailing/`
export const DEACTIVATE_MAILING = `${CLIENT}/%d/deactivate_mailing/`

export const ADDRESS_CREATE = 'e_commerce/client_addresses/'
export const ADDRESS_LIST = 'e_commerce/client_addresses/'
export const ADDRESS_DELETE = 'e_commerce/client_addresses/%d/'

const FAVORITE = 'e_commerce/favourites'
export const FAVOURITE_CREATE = `${FAVORITE}/set/`
export const FAVOURITE_LIST = `${FAVORITE}/`
export const FAVOURITE_DELETE = `${FAVORITE}/unset/`

export const FILTER_LIST = '/e_commerce/product/filters'

const COMMENT = 'e_commerce/comments/'
export const COMMENT_CREATE = `${COMMENT}`
export const COMMENT_LIST = `${COMMENT}`

const DELIVERY_TYPE = 'e_commerce/delivery_types'
export const DELIVERY_TYPE_LIST = `${DELIVERY_TYPE}/`

const PAGES = 'e_commerce/pages'
export const FOOTER_PAGES = `/${PAGES}/`
export const FOOTER_PAGES_DETAIL = `/${PAGES}/%d/`
