import React, { useState } from 'react'
import styled from 'styled-components'
import { Field, Form } from 'react-final-form'
import TextArea from 'components/UI/FormField/TextArea'
import { pathOr, path, prop } from 'ramda'
import { dateTimeFormat } from 'utils/dateFormat'
import Link from 'components/Link'
import PropTypes from 'prop-types'
import UserIcon from 'icons/UserIcon'
import getCookie from 'utils/getCookie'

const Title = styled('h2')`
  font-weight: 700;
  font-size: 23px;
  line-height: 48px;
  margin-bottom: ${({ noToken }) => noToken ? '15px' : '30px'};
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
`
const SubTitle = styled('h3')`
  font-size: 15px;
  line-height: 19px;
  font-weight: 400;
  margin-bottom: 45px;
  & a{
    font-weight: 700;
    color: ${({ theme }) => theme.palette.primary};
  }
`
const NewCommentBtn = styled('button')`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  background: #fff;
  color: #2e384c;
  border: 1px solid #2e384c;
  min-width: 176px;
  font-size: 16px;
  line-height: 21px;
  cursor: pointer;
  font-weight: 600;
  height: 46px;
`
const CommentWrap = styled('div')`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  margin-bottom: 12px;
  & > svg{
    width: 50px;
    height: 50px;
    margin: 0 22px 0 0;
  }
`
const ProfileImageStyled = styled('img')`
  width: 50px;
  margin: 0 22px 0 0;
  border-radius: 100%;
`
const CommentArea = styled('div')`
  width: calc(100% - (50px + 22px));
`
const CommentFormBlock = styled('div')`
  margin-bottom: 20px;
`
const ButtonBlock = styled('div')`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: flex-end;
  & > button:not(:last-child){
    margin-right: 24px;
  }
`
const CancelButton = styled('button')`
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #777e86;
  background: transparent;
  border: none;
  outline: 0;
  cursor: pointer;
  padding: 0;
`
const SendButton = styled('button')`
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: ${({ theme }) => theme.palette.primary};
  background: transparent;
  border: none;
  outline: 0;
  cursor: pointer;
  padding: 0;
`
const NoMessages = styled(SubTitle)``
const CommentList = styled('div')`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  & > div:not(:last-child){
    margin-bottom: 40px;
  }
`
const CommentItem = styled('div')`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  & > svg{
    width: 50px;
    height: 50px;
    margin: 0 22px 0 0;
  }
`
const CommentContent = styled('div')`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: calc(100% - (50px + 22px));
`
const CommentDate = styled('div')`
  font-size: 15px;
  line-height: 19px;
  color: #818591;
  margin-bottom: 7px;
  font-weight: 400;
`
const CommentName = styled('div')`
  font-weight: 700;
  font-size: 15px;
  line-height: 19px;
  color: #2e384c;
  margin-bottom: 14px;
`
const CommentText = styled('div')`
  font-size: 15px;
  line-height: 22px;
  color: #2e384c;
`

const Comment = (props) => {
  const {
    onSubmit,
    commentList,
    userInfo,
    token
  } = props

  // useState
  const [open, setOpen] = useState(false)

  // Const
  const commentPreview = pathOr([], ['results'], commentList)
  const userPhotoSrc = path(['data', 'image', 'file'], userInfo)
  const isEmptyMessage = path(['results'], commentList)
  const tokenToCheck = path(['data', 'token'], token) || getCookie('token') || ''
  const noToken = !tokenToCheck

  // Title
  const title =
    <Title
      noToken={noToken}
    >
      <span>
        Отзывы
      </span>
      {(!open && !noToken) &&
      <NewCommentBtn
        onClick={() => setOpen(true)}
      >
        Написать отзыв
      </NewCommentBtn>}
    </Title>

  // NoAuth
  const noAuth =
    <SubTitle>
      Чтобы оставлять отзывы, необходима <Link to="/sign-in">регистрация</Link>
    </SubTitle>

  // CommentCreate
  const commentCreate = open &&
    <CommentFormBlock>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <CommentWrap>
                {!userPhotoSrc
                  ? <UserIcon />
                  : <ProfileImageStyled src={userPhotoSrc} />}
                <CommentArea>
                  <Field
                    name={'comment'}
                    component={TextArea}
                  />
                </CommentArea>
              </CommentWrap>
              <ButtonBlock>
                <CancelButton onClick={() => setOpen(!open)}>Отмена</CancelButton>
                <SendButton type={'submit'}>Отправить</SendButton>
              </ButtonBlock>
            </form>
          )
        }}
      />
    </CommentFormBlock>

  // MessagesList
  const messagesList =
    <CommentList>
      {commentPreview.map((commentSingle, key) => {
        const createdDate = prop('createdDate', commentSingle)
        const username = path(['user', 'username'], commentSingle)
        const name = pathOr(username, ['user', 'fullName'], commentSingle)
        const photoSrc = path(['user', 'image', 'file'], commentSingle)

        return (
          <CommentItem
            key={key}
          >
            {typeof photoSrc === 'undefined'
              ? <UserIcon />
              : <ProfileImageStyled src={photoSrc} />}
            <CommentContent>
              <CommentDate>
                {dateTimeFormat(createdDate)}
              </CommentDate>
              <CommentName>
                {name}
              </CommentName>
              <CommentText>
                {commentSingle.comment}
              </CommentText>
            </CommentContent>
          </CommentItem>
        )
      })}
    </CommentList>

  // NoMessages
  const noMessages =
    <NoMessages>
      У этого продукта нет отзывов
    </NoMessages>

  // Render
  return (
    <div>
      {title}
      {noToken
        ? noAuth
        : commentCreate}
      {isEmptyMessage.length === 0
        ? noMessages
        : messagesList}
    </div>
  )
}

Comment.propTypes = {
  onSubmit: PropTypes.func,
  commentList: PropTypes.object,
  userInfo: PropTypes.object,
  token: PropTypes.object
}

export default Comment
