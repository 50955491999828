import React from 'react'

const ArrowLeft = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.8346 10H4.16797" stroke="#2E384C" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.0013 15.8333L4.16797 9.99999L10.0013 4.16666" stroke="#2E384C" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default ArrowLeft
