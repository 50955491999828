import React from 'react'
import Layout from 'components/Layout'
import HomeContainer from './HomeContainer'

// eslint-disable-next-line require-await,func-style
async function action (props) {
  return {
    title: 'Интернет магазин',
    component: (
      <Layout {...props} home={true}>
        <HomeContainer />
      </Layout>
    )
  }
}

export default action
