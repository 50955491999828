import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Wrap = styled.div`
  display: flex;
  margin-bottom: 14px;
  font-size: 15px;
  line-height: 19px;
`
const LabelWrap = styled.div`
  width: 45%;
  position: relative;
  margin-right: 10px;
  :before {
    z-index: 1;
    content: " ";
    position: absolute;
    bottom: 0.2em;
    left: 0;
    height: 2px;
    width: 100%;
    border-bottom: 1px dashed;
    border-bottom-color: #818591;
  }
`
const Label = styled.span`
  color: #818591;
  background: #fff;
  position: relative;
  z-index: 2;
  padding-right: 5px;
`
const Value = styled.span`
  white-space: nowrap;
`

const Feature = props => {
  const { children, label } = props

  return (
    <Wrap>
      <LabelWrap>
        <Label>
          {label}
        </Label>
      </LabelWrap>
      <Value>
        {children}
      </Value>
    </Wrap>
  )
}

Feature.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string
}

export default Feature
