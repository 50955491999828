import React from 'react'
import Layout from 'components/Layout'
import MyOrderContainer from './MyOrderContainer'

// eslint-disable-next-line func-style,require-await
async function action (props) {
  return {
    title: 'Заказ',
    component: (
      <Layout {...props}>
        <MyOrderContainer />
      </Layout>
    )
  }
}

export default action
