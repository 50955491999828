import React from 'react'
import setGlobalNotify from 'utils/setGlobalNotify'
import { path } from 'ramda'
import { userInfoCheckToken } from 'routes/sign-in/actions'
import getCookie from 'utils/getCookie'

export default Component => {
  return class DataLayout extends React.Component {
    constructor (props) {
      super(props)
      this.state = {
        userData: {}
      }

      this.notificationInterval = null
      this.actionSuccess = this.actionSuccess.bind(this)
      this.getNotifications = this.getNotifications.bind(this)
    }

    // componentDidUpdate = prevProps => {
    // const { store } = prevProps
    // }

    componentDidMount () {
      const { store: { getState, dispatch } } = this.props
      const state = getState()
      const stateToken = path(['login', 'data', 'token'], state)
      const cookieToken = getCookie('token')
      if (stateToken || cookieToken) {
        dispatch(userInfoCheckToken())
      }
      // dispatch(configAction())
      // dispatch(footerPagesAction())
    }

    actionSuccess () {
      const { store: { dispatch } } = this.props
      dispatch(setGlobalNotify())
    }

    getNotifications () {
      // eslint-disable-next-line react/prop-types,no-unused-vars
      const { store: { dispatch } } = this.props
      // const INTERVAL = 20000
    }

    componentWillUnmount () {
      clearTimeout(this.notificationInterval)
    }

    render () {
      const { store: { getState } } = this.props
      const state = getState()
      const isAuth = path(['login', 'data', 'token'], state) || getCookie('token') || ''
      const data = {
        isAuth
      }
      return (
        <Component
          {...this.props}
          {...data}
        />
      )
    }
  }
}
