import * as STATE from 'constants/stateNames'
import React from 'react'
import { isEmpty, path, prop } from 'ramda'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import MyOrder from 'icons/myOrders'
import SignOut from 'icons/SignOut'
import UserIcon from 'icons/UserIcon'
import Link from '../../Link'
import ProfileIcon from '../../../icons/Profile'
import { Dropdown } from '../Dropdown'
import FavoriteIcon from '../../../icons/Favorite'
import Settings from '../../../icons/Settings'
import { compose } from 'redux'
import { connect } from 'react-redux'

const SingInLink = styled(Link)`
  margin-left: auto;
  padding: 5px;
  display: flex;
  align-items: center;
  height: 78px;
  font-size: 16px;
  line-height: 164.57%;
  color: #fff !important;
  cursor: pointer;
  user-select: none;
  &:hover {
    color: #fff;
  }
`
const ProfileLink = styled('div')`
  margin-left: auto;
  padding: 5px;
  display: flex;
  align-items: center;
  height: 78px;
  font-size: 16px;
  line-height: 164.57%;
  color: #fff !important;
  cursor: pointer;
  user-select: none;
  &:hover {
    color: #fff;
  }
`
const ProfileImageStyled = styled.img`
  width: 34px;
  height: 34px;
  border-radius: 50%;
`
const DropdownTexts = styled('span')`
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  color: #242f3b;
  margin-left: 10px;
`
const StyledProfileIcon = styled.div`
  margin-right: 8px;
  margin-bottom: 2px;
  svg{
    width: 22px;
    height: 22px;
    font-size: 22px;
  }
`
const UserBlock = styled('div')`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`
const UserName = styled('div')`
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  color: #242f3b;
`
const UserPhone = styled('div')`
  font-size: 13px;
  line-height: 19px;
  color: #818591;
`
const ProfileItem = styled('div')`
  display:  flex;
  flex-flow: row nowrap;
`
const DropdownItem = styled('span')`
  display: flex;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 12px;
  svg{
    stroke: ${({ theme }) => theme.palette.primary};
    width: 17px;
    font-size: 17px;
  }
  &:hover{
    cursor: pointer;
    span{
      color: ${({ theme }) => theme.palette.primary};
    }
  }
`

const enhance = compose(
  connect(state => {
    return {
      [STATE.USER_INFO]: prop(STATE.USER_INFO, state)
    }
  })
)

const AuthMenu = props => {
  const {
    isAuth,
    onSignOut,
    userInfo
  } = props

  const username = path(['data', 'username'], userInfo)
  const fullName = path(['data', 'fullName'], userInfo)
  const photo = path(['data', 'image', 'file'], userInfo)

  return isEmpty(isAuth)
    ? (
      <SingInLink
        to={'/sign-in'}
      >
        <StyledProfileIcon>
          <ProfileIcon />
        </StyledProfileIcon>
        Вход
      </SingInLink>)
    : (
      <ProfileLink>
        <StyledProfileIcon>
          <ProfileIcon />
        </StyledProfileIcon>
        <Dropdown
          title={'Профиль'}
          to={'/profile'}
        >
          <Link to={'/profile'}>
            <ProfileItem>
              {typeof photo === 'undefined'
                ? <UserIcon />
                : <ProfileImageStyled src={photo} />}
              <UserBlock>
                <UserName>
                  {fullName || 'Клиент'}
                </UserName>
                <UserPhone>
                  {username || '+998000000000'}
                </UserPhone>
              </UserBlock>
            </ProfileItem>
          </Link>
          <hr />
          <Link to={'/my-order'}>
            <DropdownItem>
              <MyOrder />
              <DropdownTexts>
                Мои заказы
              </DropdownTexts>
            </DropdownItem>
          </Link>
          <Link to={'/favourite'}>
            <DropdownItem>
              <FavoriteIcon />
              <DropdownTexts>
                Избранные товары
              </DropdownTexts>
            </DropdownItem>
          </Link>
          <hr />
          <Link to={'/profile'}>
            <DropdownItem>
              <Settings />
              <DropdownTexts>
                Настройки
              </DropdownTexts>
            </DropdownItem>
          </Link>
          <hr />
          <DropdownItem
            onClick={onSignOut}
          >
            <SignOut />
            <DropdownTexts>
              Выход
            </DropdownTexts>
          </DropdownItem>
        </Dropdown>
      </ProfileLink>
    )
}

AuthMenu.propTypes = {
  isAuth: PropTypes.string,
  onSignOut: PropTypes.func
}
export default enhance(AuthMenu)
