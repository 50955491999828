import React from 'react'
import styled from 'styled-components'
import Link from 'components/Link'
import Payme from 'icons/Payme.svg'
import Click from 'icons/Click.svg'
import Cash from 'icons/Cash.svg'
import BankIcon from 'icons/BankIcon.svg'
import PropTypes from 'prop-types'
import numberFormat from 'utils/numberFormat'
import getCookie from 'utils/getCookie'
import { isEmpty } from 'ramda'

const CartInfoBlock = styled.div`
  position: sticky;
  top: 130px;
  background: #fff;
  padding: 25px 20px 20px;
  border-radius: 5px;
  width: 290px;
`
const CartInfoItem = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 164.57%;
  color: #2e384c;
  margin-bottom: 5px;
`
const CartInfoItem1 = styled.div`
  font-size: 13px;
  line-height: 18px;
  color: #818591;
`
const DeliveryBlock = styled.div`
  margin-bottom: 15px;
`
const WalletBlock = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-bottom: 25px;
  & > img{
    max-width: 60px;
  }
`
const PriceBlock = styled.div``
const PriceBlockItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  color: #2e384c;
`
const PriceTotalBlock = styled(PriceBlockItem)`
  line-height: 164.57%;
  color: #2e384c;
  h6{
    font-size: 15px;
    font-weight: 600;
  }
  div{
    font-weight: 700;
    font-size: 16px;
  }
`
const SubmitButton = styled('button')`
  width: 100%;
  color: #fff;
  outline: 0;
  padding: 15px;
  cursor: pointer;
  background: ${({ theme }) => theme.palette.primary};
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  border: 1px solid transparent;
  &:disabled{
    cursor: no-drop;
    background: ${({ disabled }) => disabled && '#f1f1f1'};
    color: #ababab;
    border-color: #dbdbdd;
  }
`
const Line = styled.div`
  border-bottom: 1px solid #eaeaec;
  margin-bottom: 15px;
  margin-top: 15px;
`

const CartInfo = props => {
  const {
    totalPrice,
    productAmount,
    deliveryPrice = 0,
    products
  } = props

  // Const
  const hasToken = !!getCookie('token')
  const noProducts = isEmpty(products) || !products

  // Render
  return (
    <CartInfoBlock>
      <DeliveryBlock>
        <CartInfoItem>Любая форма оплаты</CartInfoItem>
        <CartInfoItem1>Картой онлайн, наличными и через терминал при получении</CartInfoItem1>
      </DeliveryBlock>
      <WalletBlock>
        <img src={Payme} alt="Payme" />
        <img src={Click} alt="Click" />
        <img src={Cash} alt="Cash" />
        <img src={BankIcon} alt="BankIcon" />
      </WalletBlock>
      <Line />
      <PriceBlock>
        <PriceBlockItem>
          <div>Товары ({productAmount})</div>
          <div>{numberFormat(totalPrice, 'сум')}</div>
        </PriceBlockItem>
        {deliveryPrice !== 0 &&
        <PriceBlockItem>
          <div>Доставка</div>
          <div>{numberFormat(deliveryPrice, 'сум')}</div>
        </PriceBlockItem>}
        <Line />
        <PriceTotalBlock>
          <h6>Итого</h6>
          <div>{numberFormat((+totalPrice + deliveryPrice) || 0, 'сум')}</div>
        </PriceTotalBlock>
      </PriceBlock>
      {
        !hasToken ? (
          <Link to="/sign-in">
            <SubmitButton>
              Регистрация
            </SubmitButton>
          </Link>
        ) : (
          <Link to="/order">
            <SubmitButton
              disabled={noProducts}
            >
              Продолжить
            </SubmitButton>
          </Link>
        )
      }
    </CartInfoBlock>
  )
}

CartInfo.propTypes = {
  totalPrice: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.number
  ]),
  productAmount: PropTypes.number,
  deliveryPrice: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ])
}

export default CartInfo
