import * as STATE from 'constants/stateNames'
import * as ROUTES from 'constants/routes'
import React, { useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { path, find, propEq, pathOr, isEmpty, prop } from 'ramda'
import equals from 'fast-deep-equal'
import { CartButton, Button } from 'components/UI/Button'
import { getDataFromState } from 'utils/get'
import { setItemToCart } from 'components/Cards/storage'
import { favouriteCreateAction, favouriteDeleteAction } from 'routes/favourite/actions'
import Comment from 'components/Comment'
import Container from 'components/Container'
import numberFormat from 'utils/numberFormat'
import SalePrice from 'components/UI/SalePrice'
import FavoriteIcon from 'icons/Favorite'
import payme from 'icons/Payme.svg'
import click from 'icons/Click.svg'
import uzcard from 'icons/Cash.svg'
import ProductsTitle from 'components/UI/ProductsTitle'
import ProductCardList from 'components/Cards/ProductCardList'
import Breadcrumbs from 'components/Breadcrumbs'
import { sprintf } from 'sprintf-js'
import NoImage from 'images/NoImage.png'
import { removeItemFrom } from 'routes/cart/actions'
import Carusel from '../../../components/UI/Carusel'
import Feature from './Feature'

const Wrapper = styled.div`
  background: #fff;
`
const ContainerUI = styled('div')`
  margin: 0 auto;
  max-width: 1170px;
  background-color: #fff;
  padding: 35px 15px 40px;
`
const BottomContainer = styled(ContainerUI)`
  & > *:not(:last-child){
    margin-bottom: 30px;
  }
`
const Cover = styled('div')`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 82px;
`
const ImagesCarusel = styled('div')`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 54px;
  padding: 23px 0;
  margin-right: 20px;
`
const ImageOptions = styled('div')`
  width: 100%;
  cursor: pointer;
  background: #fff;
  overflow: hidden;
  border: ${({ active, theme }) => active ? `2px solid ${theme.palette.primary}` : '2px solid transparent'};
  border-radius: 5px;
  margin-bottom: 3px;
  padding: 1px;
  min-height: 54px;
  display: flex;
  align-items: center;
  img{
    width: 100%;
    border-radius: 3px;
  }
`
const MainImg = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 444px;
  min-height: 444px;
  margin-right: 44px;
  img{
    min-height: 444px;
    max-width: 100%;
    max-height: 100%;
  }
`
const ProductCover = styled('div')`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: calc(100% - (54px + 20px + 444px + 44px));
`
const Artikul = styled('div')`
  font-size: 15px;
  line-height: 19px;
  color: #818591;
  margin-bottom: 7px;
`
const Title = styled('h1')`
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #2e384c;
  margin: 0 0 11px;
`
const Ranks = styled('div')`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  //margin-bottom: 34px;
  margin-bottom: 25px;
  & > *:not(:last-child){
    margin-right: 27px;
  }
`
const CommentsCount = styled('div')`
  font-size: 15px;
  line-height: 19px;
  color: #818591;
`
const PriceWrap = styled('div')`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-end;
  margin-bottom: 28px;
  & > *:not(:last-child){
    margin-right: 15px;
  }
`
const Price = styled('div')`
  font-weight: 700;
  font-size: 30px;
  line-height: 39px;
  color: ${({ active, theme }) => active ? theme.palette.greenDark : theme.palette.black};
`
const ProductCoverButtons = styled('div')`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  margin-bottom: 30px;
  & > *{
    margin: 0 !important;
    height: 50px !important;
  }
  & > *:not(:last-child){
    margin: 0 16px 0 0 !important;
  }
`
const FavouriteButton = styled('button')`
  min-width: 200px;
  background: #fff;
  color: ${({ favourite, theme }) => favourite ? theme.palette.red : '#818591'};
  border: ${({ favourite, theme }) => favourite ? `1px solid ${theme.palette.red}` : '1px solid #818591'};
  border-radius: 3px;
  padding: 5px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  line-height: 21px;
  cursor: pointer;
  font-weight: 500;
  & > svg {
    stroke: ${({ favourite, theme }) => favourite ? theme.palette.red : '#818591'};
    fill: ${({ favourite, theme }) => favourite ? theme.palette.red : theme.palette.white};
    transition: none;
    margin-right: 5px;
  }
`
const BrendTitle = styled('div')`
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  color: #2e384c;
  margin-bottom: 10px;
`
const BrendName = styled('div')`
  font-size: 15px;
  line-height: 19px;
  color: #2e384c;
  margin-bottom: 35px;
`
const Payment = styled('div')`
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  color: #2e384c;
  margin-bottom: 14px;
`
const PaymentImages = styled('div')`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  & > img{
    max-height: 25px;
  }
  & > *:not(:last-child){
    margin-right: 15px;
  }
`
const PaymentImage = styled('img')``
const SubTitle = styled('h2')`
  font-weight: 700;
  font-size: 23px;
  line-height: 38px;
  margin-bottom: 12px;
`
const Descr = styled('p')`
  font-size: 15px;
  line-height: 22px;
  margin-bottom: 44px;
`
const PopularListBlock = styled('div')`
  background: ${({ theme }) => theme.background.primary};
  padding: 40px 0;
`
const PopularProduct = styled('h3')`
  font-weight: 700;
  font-size: 23px;
  line-height: 38px;
  color: #2e384c;
  margin: 0 0 20px;
`
const ProductListBlock = styled('div')`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  background: #fff;
  border-radius: 5px;
`

const Product = props => {
  const {
    productData,
    onSubmit,
    commentList,
    userInfo,
    token,
    popularData
  } = props

  // useState
  const [selectedImgIndex, setSelectedImgIndex] = useState(0)

  const dispatch = useDispatch()
  const cartList = useSelector(getDataFromState(STATE.CART), equals)

  const datas = pathOr([], ['data'], cartList)
  const data = path(['data'], productData)
  const name = path(['name'], data)
  const price = path(['price'], data)
  const oldPrice = prop('oldPrice', data)
  const type = prop('type', data)
  const categoryId = prop('id', type)
  const categoryName = prop('name', type)
  const parentType = prop('parent', type)
  const parentCategoryId = prop('id', parentType)
  const parentCategoryName = prop('name', parentType)
  const country = path(['country', 'name'], data)
  const brand = path(['brand', 'name'], data)
  const productOptions = path(['productOptions'], data)
  const description = path(['description'], data)
  const id = path(['id'], data)
  const filterProduct = find(propEq('id', id))(datas)
  const amount = pathOr(0, ['amount'], filterProduct)
  const isFavourite = path(['data', 'isFavourite'], productData)
  const images = pathOr([], ['images'], data)
  const mainImage = prop(selectedImgIndex, images)
  const commentsCount = pathOr(0, ['data', 'count'], commentList)
  const barcode = path(['barcodes', 0], data)
  // const rate = propOr(10, 'ball', data)
  const measurement = prop('measurement', data)
  const measurementName = prop('name', measurement)
  const isCustomWeight = measurementName &&
    (measurementName.toLowerCase() === 'кг' || measurementName.toLowerCase() === 'kg')

  const mathValue = isCustomWeight ? 0.2 : 1

  const handleAddFirst = () => dispatch(setItemToCart(mathValue, data))
  const handleAdd = (product, value) => {
    return dispatch(setItemToCart((+value + mathValue).toFixed(1), product))
  }
  const handleRemove = (product, value) => {
    return dispatch(setItemToCart((+value - mathValue).toFixed(1), product))
  }
  const handleDelete = () => {
    return dispatch(removeItemFrom(id))
  }

  const [favourite, setFavourite] = useState(isFavourite)

  const onFavourite = () => {
    setFavourite(!favourite)
    favourite ? dispatch(favouriteDeleteAction(id)) : dispatch(favouriteCreateAction(id))
  }

  // ImageOptionsList
  const imageOptionsList = images.map((item, index) => {
    return (
      <ImageOptions
        key={index}
        active={+selectedImgIndex === index}
      >
        <img
          src={prop('file', item)}
          onClick={() => setSelectedImgIndex(index)}
          alt={categoryName}
        />
      </ImageOptions>
    )
  })

  // BreadcrumbsWay
  const breadcrumbsWay = [
    {
      title: 'Главная',
      url: ROUTES.HOME
    },
    (parentType && !isEmpty(parentType)) && {
      title: parentCategoryName,
      url: sprintf(ROUTES.CATEGORIES_URL, parentCategoryId)
    },
    {
      title: categoryName || 'Категория',
      url: sprintf(ROUTES.CATEGORIES_URL, categoryId)
    }
  ]

  return (
    <Wrapper>
      <ContainerUI>
        <Breadcrumbs
          way={breadcrumbsWay}
          currentWay={name}
        />
        <Cover>
          <ImagesCarusel>
            {(!images || isEmpty(images))
              ? (
                <ImageOptions
                  active={true}
                >
                  <img
                    src={NoImage}
                    alt={categoryName}
                  />
                </ImageOptions>
              )
              : imageOptionsList}
          </ImagesCarusel>
          <MainImg>
            <img src={isEmpty(images) ? NoImage : prop('file', mainImage)} alt={name} />
          </MainImg>
          <ProductCover>
            {barcode &&
              <Artikul>{`артикул: ${barcode}`}</Artikul>}
            <Title>{name}</Title>
            <Ranks>
              {/* <Rating rate={rate} /> */}
              <CommentsCount>
                {`${commentsCount} отзывов`}
              </CommentsCount>
            </Ranks>
            <PriceWrap>
              <Price
                active={true}
              >
                {numberFormat(price, 'сум')}
              </Price>
              <SalePrice
                price={oldPrice}
                bold={600}
                fontSize={'16px'}
                style={{ marginBottom: '7px' }}
              />
            </PriceWrap>
            <ProductCoverButtons>
              {filterProduct
                ? (
                  <CartButton
                    amount={amount}
                    onDelete={handleDelete}
                    onAdd={() => handleAdd(prop('data', productData), amount)}
                    onRemove={() => handleRemove(prop('data', productData), amount)}
                    minWidth={'200px'}
                    withSuffix={true}
                    withDelete={true}
                    measurement={measurementName}
                  />
                )
                : (
                  <Button onClick={handleAddFirst}>
                    В корзину
                  </Button>
                )}

              <FavouriteButton
                favourite={favourite}
                onClick={onFavourite}
              >
                <FavoriteIcon />
                <span>
                  {favourite
                    ? 'В избранном'
                    : 'В избранное'}
                </span>
              </FavouriteButton>
            </ProductCoverButtons>
            <BrendTitle>Бренд (производитель)</BrendTitle>
            <BrendName>{brand}</BrendName>
            <Payment>Оплата</Payment>
            <PaymentImages>
              <PaymentImage src={payme} alt="payme" />
              <PaymentImage src={click} alt="click" />
              <PaymentImage src={uzcard} alt="uzcard" />
            </PaymentImages>
          </ProductCover>
        </Cover>
        <SubTitle>Описание товара</SubTitle>
        <Descr>
          {description}
        </Descr>
        <SubTitle>Основное</SubTitle>
        {country &&
        <Feature label="Страна-изготовитель">{country}</Feature>}
        {brand &&
        <Feature label="Бренд">{brand}</Feature>}
        {productOptions.map(option => {
          const label = path(['optionsValue', 'option', 'name'], option)
          const value = path(['optionsValue', 'value'], option)
          return (
            <Feature key={option.id} label={label}>{value}</Feature>
          )
        })}
        <ProductsTitle title="Новинки" />
      </ContainerUI>
      <PopularListBlock>
        <Container>
          <PopularProduct>Похожие товары</PopularProduct>
          <ProductListBlock>
            <ProductCardList
              productData={popularData}
              column={4}
            />
          </ProductListBlock>
        </Container>
      </PopularListBlock>
      <BottomContainer>
        <Carusel
          section={'bottom'}
        />
        <Comment onSubmit={onSubmit} commentList={commentList} userInfo={userInfo} token={token} />
      </BottomContainer>
    </Wrapper>
  )
}

export default Product
