import React from 'react'
import ShoppingBag from 'icons/ShoppingBag'
import numberFormat from 'utils/numberFormat'
import styled from 'styled-components'
import Link from 'components/Link'
import { find, isEmpty, prop, propEq, propOr } from 'ramda'
import NoImage from 'images/NoImage.png'
import getCookie from 'utils/getCookie'
import { CartButton } from 'components/UI/Button'
import { setItemToCart } from 'components/Cards/storage'
import { removeItemFrom } from 'routes/cart/actions'
import { useDispatch } from 'react-redux'

const DropdownContent = styled('div')`
  display: none;
  position: absolute;
  right: 0;
  top: 72px;
  background: #fff;
  color: #242f3b;
  border-radius: 5px;
  width: 305px;
  box-shadow: ${({ theme }) => theme.boxShadow.modal};
  padding: 0 0 12px;
  z-index: 100;
  cursor: default;
  overflow-y: auto;
`
const ProductsList = styled('div')`
  max-height: 400px;
  overflow-y: auto;
`
const DropdownStyled = styled('div')`
  position: relative;
  display: block;
  height: 74px;
  margin-left: auto;
  &:hover ${DropdownContent} {
    display: ${({ noProducts }) => !noProducts && 'block'};
    transition: opacity 2s;
  }
`
const CartLink = styled(Link)`
  position: relative;
  padding: 5px;
  display: flex;
  align-items: center;
  height: 78px;
  font-size: 16px;
  line-height: 164.57%;
  color: #fff;
  cursor: pointer;
  user-select: none;
  :hover {
    color: #fff;
  }
  svg {
    font-size: 22px;
    width: 22px;
    height: 22px;
    margin-right: 14px;
  }
`
const CardText = styled('div')`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  min-width: 92px;
  b{
    font-weight: 400;
  }
  span{
    font-size: 13px;
    line-height: 16px;
    font-weight: 500;
  }
`
const CartTitle = styled('div')`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
`
const CartCount = styled('span')`
  position: absolute;
  left: 15px;
  top: 22px;
  min-width: 20px;
  min-height: 20px;
  font-weight: 600;
  font-size: 11px;
  padding: 2px;
  line-height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border: 2px solid #6a82c2;
  color: ${({ theme }) => theme.palette.primary};
  border-radius: 30px;
`
const LinkButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 24px);
  color: #fff;
  padding: 15px;
  cursor: pointer;
  background: ${({ theme }) => theme.palette.primary};
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  height: 40px;
  margin: 10px 12px 0;
  &:hover{
    color: #fff;
  }
`
const CartItem = styled('div')`
  position: relative;
  a{
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0 12px;
    border-bottom: ${({ theme }) => theme.border.primary};
    padding: 12px 0;
  }
  img{
    width: 50px;
    height: 50px;
    margin-right: 8px;
  }
  &:last-child{
    a{
      margin-bottom: 0;
      border-bottom: none;
    }
  }
`
const CartRightSide = styled('div')`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 35px;
  h4{
    font-weight: 400;
    margin: 0 0 2px;
    font-size: 13px;
    line-height: 15px;
    color: ${({ theme }) => theme.color.primary};
  }
`
const CartButtonWrap = styled('div')`
  position: absolute;
  left: 69px;
  bottom: 10px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 28px - 58px);
  z-index: 100;
`
const Price = styled('span')`
  display: inline-block;
  color: ${({ theme }) => theme.palette.primary};
  font-size: 15px;
  font-weight: 500;
`

const CartDropdown = props => {
  const {
    totalPrice,
    productsLength,
    products
  } = props

  // Const
  const noProducts = isEmpty(products)
  const hasToken = !!getCookie('token')
  const dispatch = useDispatch()

  // CartCount
  const cartCount =
    <CartCount>
      {productsLength}
    </CartCount>

  // HandleFunctions
  const handleAdd = (product, value, mathValue) => {
    return dispatch(setItemToCart((+value + mathValue).toFixed(1), product))
  }
  const handleRemove = (product, value, mathValue) => {
    return dispatch(setItemToCart((+value - mathValue).toFixed(1), product))
  }
  const handleDelete = id => {
    return dispatch(removeItemFrom(id))
  }

  // Render
  return (
    <DropdownStyled
      noProducts={noProducts}
    >
      <CartLink
        to={'/cart'}
      >
        <ShoppingBag />
        {!noProducts &&
        cartCount}
        <CardText>
          <CartTitle>
            <b>Корзина</b>
          </CartTitle>
          {!noProducts &&
          <span>
            {numberFormat(totalPrice, 'сум')}
          </span>}
        </CardText>
      </CartLink>
      <DropdownContent>
        <ProductsList>
          {!noProducts && products.map((item, index) => {
            const id = prop('id', item)
            const name = prop('name', item)
            const price = propOr(0, 'price', item)
            const amount = propOr(0, 'amount', item)
            const images = propOr([], 'images', item)
            const isPrimary = find(propEq('isPrimary', true))(images)
            const imgSrc = prop('file', isPrimary)

            const measurement = prop('measurement', item)
            const measurementName = prop('name', measurement)

            const isCustomWeight = measurementName &&
              (measurementName.toLowerCase() === 'кг' || measurementName.toLowerCase() === 'kg')

            const mathValue = isCustomWeight ? 0.2 : 1

            return (
              <CartItem
                key={index}
              >
                <Link
                  to={'/product/' + id}
                >
                  <img src={imgSrc || NoImage} alt={name} />
                  <CartRightSide>
                    <h4>
                      {name}
                    </h4>
                  </CartRightSide>
                </Link>
                <CartButtonWrap>
                  <CartButton
                    amount={amount}
                    onDelete={() => handleDelete(id)}
                    onAdd={() => handleAdd(item, amount, mathValue)}
                    onRemove={() => handleRemove(item, amount, mathValue)}
                    minWidth={'103px'}
                    height={'32px'}
                    fontSize={'14px'}
                    withDelete={true}
                    withSuffix={true}
                    measurement={measurementName}
                  />
                  <Price>
                    {numberFormat(price, 'сум')}
                  </Price>
                </CartButtonWrap>
              </CartItem>
            )
          })}
        </ProductsList>
        {!hasToken ? (
          <LinkButton to="/sign-in">
            Регистрация
          </LinkButton>
        ) : (
          <LinkButton to="/order">
            Оформить заказ
          </LinkButton>
        )}
      </DropdownContent>
    </DropdownStyled>
  )
}

export default CartDropdown
