import * as STATE from 'constants/stateNames'
import React, { useEffect, useRef, useState } from 'react'
import { pathOr, path } from 'ramda'
import styled from 'styled-components'
import Link from 'components/Link'
import MenuBarIcon from 'icons/MenuBar'
import { useSelector } from 'react-redux'
import { getDataFromState } from 'utils/get'
import XIcon from 'icons/XIcon'

const DropdownBlock = styled('div')`
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  margin-left: 43px;
  padding: 5px;
  height: 78px;
  cursor: pointer;
`
const LogoStyled = styled('div')`
  border-radius: 4px;
  background: ${({ open, theme }) => open ? theme.palette.white : theme.palette.primaryLight};
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .2s linear;
  svg{
    width: 22px;
    height: 22px;
    stroke: ${({ open, theme }) => open ? theme.palette.primary : theme.palette.white};
    transition: all .2s linear;
    &:first-child{
      display: ${({ open }) => open ? 'block' : 'none'};
    }
    &:last-child{
      display: ${({ open }) => !open ? 'block' : 'none'};
    }
  }
`
const DropdownStyled = styled('div')``
const StyledTitle = styled('div')`
  color: #fff;
  font-weight: 500;
  margin-left: 10px;
  font-size: 16px;
  user-select: none;
`
const DropdownContent = styled('div')`
  position: fixed;
  overflow-y: auto;
  top: 106px;
  bottom: 0;
  right: 0;
  left: 0;
  background: #fff;
  color: #000;
  width: 100%;
  z-index: 1;
  cursor: default;
`
const WrapContent = styled('div')`
  min-height: calc(100vh - 106px);
  padding: 35px 15px;
  width: 1170px;
  max-width: 100%;
  margin: 0 auto;
  column-count: 4;
`
const DropdownItem = styled('div')`
  display: inline-block;
  margin-bottom: 20px;
  padding-right: 25px;
  align-items: center;
  page-break-inside: avoid;
`
const DropdownTexts = styled('div')`
  font-size: 15px;
  line-height: 129.96%;
  color: ${({ theme }) => theme.color.primary};
  font-weight: 600;
  margin-bottom: 10px;
  &:hover{
    color: ${({ theme }) => theme.palette.primary};
  }
`
const DropdownSubText = styled('div')`
  font-size: 15px;
  margin-bottom: 7px;
  line-height: 130%;
  color: ${({ theme }) => theme.color.primary};
  &:hover{
    color: ${({ theme }) => theme.palette.primary};
  }
`

const defArray = []

const Catalog = () => {
  const [open, setMenuOpen] = useState(false)

  // Ref
  const menuRef = useRef(null)

  // useEffect
  useEffect(() => {
    const listener = event => {
      if (!menuRef.current.contains(event.target)) {
        setMenuOpen(false)
      }
    }
    window.addEventListener('click', listener)
    return () => window.removeEventListener('click', listener)
    // eslint-disable-next-line
  }, [open])

  const menuData = useSelector(getDataFromState(STATE.MENU_AS))
  const lists = pathOr(defArray, ['results'], menuData)

  // DropdownContent
  const dropdownContent =
    <DropdownContent>
      <WrapContent>
        {lists.map(list => {
          const parentId = path(['id'], list)
          const subMenu = path(['children'], list)
          return (
            <DropdownItem
              key={parentId}
            >
              <Link to={`/categories/${parentId}`} beforeRedirect={() => setMenuOpen(false)}>
                <DropdownTexts>{list.name}</DropdownTexts>
              </Link>
              {subMenu.map((sub) => {
                const subId = path(['id'], sub)
                return (
                  <Link key={subId} to={`/categories/${subId}`} beforeRedirect={() => setMenuOpen(false)}>
                    <DropdownSubText>{sub.name}</DropdownSubText>
                  </Link>
                )
              })}
            </DropdownItem>
          )
        })}
      </WrapContent>
    </DropdownContent>

  return (
    <DropdownBlock
      onClick={() => setMenuOpen(!open)}
      ref={menuRef}
    >
      <LogoStyled
        open={open}
      >
        <XIcon />
        <MenuBarIcon />
      </LogoStyled>
      <DropdownStyled>
        <StyledTitle>
          Каталог&nbsp;товаров
        </StyledTitle>
        {open && dropdownContent}
      </DropdownStyled>
    </DropdownBlock>
  )
}

export default Catalog
