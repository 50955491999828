import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const FavButton = styled.button`
    background: ${props => props.favourite ? 'red' : 'none'};
    border: 1px solid #818591;
    box-sizing: border-box;
    border-radius: 3px;
`

const FavouriteButton = (props) => {
  const { favourite } = props
  return (
    <FavButton favourite={favourite}>
      В избранное
    </FavButton>
  )
}

FavouriteButton.propTypes = {
  favourite: PropTypes.bool
}

export default FavouriteButton
