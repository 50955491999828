import * as STATE from 'constants/stateNames'
import React from 'react'
import useFetchList, { getListParams } from '../../hooks/useFetchList'
import Home from './Home'
import { getProductList } from './actions'
import * as actionTypes from 'constants/actionTypes'

const HomeContainer = () => {
  const productDataPopular = useFetchList({
    action: getProductList,
    stateName: STATE.PRODUCT_LIST_POPULAR,
    mapper: (props, key) => {
      const defaultListParams = getListParams(props, key)
      return {
        ...defaultListParams,
        ordering: 'popular',
        actionType: actionTypes.PRODUCT_LIST_POPULAR
      }
    }
  })
  const productDataNew = useFetchList({
    action: getProductList,
    stateName: STATE.PRODUCT_LIST_NEW,
    mapper: (props, key) => {
      const defaultListParams = getListParams(props, key)
      return {
        ...defaultListParams,
        ordering: 'news',
        actionType: actionTypes.PRODUCT_LIST_NEW
      }
    }
  })
  const productDataMonth = useFetchList({
    action: getProductList,
    stateName: STATE.PRODUCT_LIST_MONTH,
    mapper: (props, key) => {
      const defaultListParams = getListParams(props, key)
      return {
        ...defaultListParams,
        ordering: 'good_in_month',
        actionType: actionTypes.PRODUCT_LIST_MONTH
      }
    }
  })

  return (
    <Home
      productDataPopular={productDataPopular}
      productDataNew={productDataNew}
      productDataMonth={productDataMonth}
    />
  )
}

export default HomeContainer
