import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import InputLabel from '../InputLabel'

const Wrap = styled('div')`
  &:not(:last-child){
    border-bottom: 1px solid #efefef;
  }
`
const Group = styled.ul`
  display: flex;
  list-style-type: none;
  flex-direction: column;
  width: 100%;
  height: ${props => props.count > 7 ? '200px' : 'auto'};
  overflow-y: scroll;
  overflow-x: hidden;
  margin-bottom: 20px;
  padding: 0 20px;
  & > :not(:last-child){
    margin-bottom: 12px;
  }
`
const HeaderBlock = styled('div')`
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  color: #2e384c;
  padding: 15px 20px;
`

const GroupLinks = props => {
  const {
    label,
    children
  } = props

  return (
    <Wrap>
      <HeaderBlock>
        <InputLabel>{label}</InputLabel>
      </HeaderBlock>
      <Group>
        {children}
      </Group>
    </Wrap>
  )
}

GroupLinks.propTypes = {
  label: PropTypes.string,
}

GroupLinks.defaultProps = {
  mode: 'inline'
}

export default GroupLinks
