import React from 'react'
import styled from 'styled-components'
import MapPinIcon from 'icons/MapPinIcon'
import TelegramIcon from 'icons/TelegramIcon'
import PhoneIcon from 'icons/PhoneIcon'
import Container from '../../Container'
import { useSelector } from 'react-redux'
import { getDataFromState } from 'utils/get'
import * as STATE from 'constants/stateNames'
import equals from 'fast-deep-equal'
import { prop, propOr } from 'ramda'
import PlayMarketIcon from 'icons/PlayMarketIcon'
import AppStoreIcon from 'icons/AppStoreIcon'

const TopHeaderWidth = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.palette.primaryDark};
`
const TopHeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 28px;
  color: white;
  font-size: 12px;
  line-height: 164.57%;
  margin: auto;
`
const LeftSide = styled.div`
  display: flex;
  & > *{
    margin-right: 40px;
  }
`
const RightSide = styled.div`
  display: flex;
  & > *:not(:first-child){
    margin-left: 40px;
  }
`
const NavLink = styled('a')`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.white};
  &:hover{
    color: unset;
    text-decoration: underline;
  }
`
const PhoneNumber = styled(NavLink)`
  svg{
    font-size: 13px;
    margin: 0 8px 2px 0;
  }
`
const AppLink = styled(NavLink)`
  svg{
    font-size: ${({ iconSize }) => iconSize || '16px'};
    margin: 0 6px 2px 0;
  }
`
const Region = styled.div`
  display: flex;
  cursor: default;
`
const RegionTitle = styled.div`
  margin-right: 5px;
  svg{
    margin-bottom: 4px;
    font-size: 14px;
    margin-right: 2px;
  }
`
const RegionValue = styled('div')``

const TopHeader = () => {
  // Data
  const config = useSelector(getDataFromState(STATE.CONFIG), equals)
  const configData = prop('data', config)

  // Const
  const phoneNumber = prop('pHONENUMBER', configData)
  const phoneNumberSecond = prop('pHONENUMBERSECOND', configData)
  const phoneNumberText = prop('pHONENUMBERTEXT', configData)
  const phoneNumberSecondText = prop('pHONENUMBERSECONDTEXT', configData)
  const telegramChannelText = propOr('Telegram', 'tELEGRAMCHANNELTEXT', configData)
  const telegramBotText = propOr('Telegram', 'tELEGRAMBOTTEXT', configData)
  const telegramChannelLink = propOr('#', 'tELEGRAMCHANNELLINK', configData)
  const telegramBotLink = propOr('#', 'tELEGRAMBOTLINK', configData)
  const playMarketLink = propOr('#', 'pLAYMARKETLINK', configData)
  const appStoreLink = propOr('#', 'aPPSTORELINK', configData)
  const showPlayMarketLink = propOr(false, 'sHOWPLAYMARKETLINK', configData)
  const showAppStoreLink = propOr(false, 'sHOWAPPSTORELINK', configData)
  // Render
  return (
    <TopHeaderWidth>
      <Container>
        <TopHeaderStyled>
          <LeftSide>
            <PhoneNumber
              href={`tel:+${phoneNumber}`}
            >
              <PhoneIcon />
              {phoneNumberText}
            </PhoneNumber>
            <PhoneNumber
              href={`tel:+${phoneNumberSecond}`}
            >
              <PhoneIcon />
              {phoneNumberSecondText}
            </PhoneNumber>
          </LeftSide>
          <RightSide>
            {showAppStoreLink &&
            <AppLink
              target={'_blank'}
              href={appStoreLink}
            >
              <AppStoreIcon />
              AppStore
            </AppLink>}
            {showPlayMarketLink &&
            <AppLink
              target={'_blank'}
              href={playMarketLink}
              iconSize={'14px'}
            >
              <PlayMarketIcon />
              PlayMarket
            </AppLink>}
            <AppLink
              target={'_blank'}
              href={telegramBotLink}
            >
              <TelegramIcon />
              {telegramBotText}
            </AppLink>
            <AppLink
              target={'_blank'}
              href={telegramChannelLink}
            >
              <TelegramIcon />
              {telegramChannelText}
            </AppLink>
            <Region>
              <RegionTitle>
                <MapPinIcon /> Регион:
              </RegionTitle>
              <RegionValue>
                Ташкент
              </RegionValue>
            </Region>
          </RightSide>
        </TopHeaderStyled>
      </Container>
    </TopHeaderWidth>
  )
}

export default TopHeader
