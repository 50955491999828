import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const ProductContentStyled = styled.div`
    color: #242f3b;
    font-size: 14px;
    line-height: 155%;
`

const ProductContent = (props) => {
  const { content } = props
  return (
    <ProductContentStyled>
      {content}
    </ProductContentStyled>
  )
}

ProductContent.propTypes = {
  content: PropTypes.string
}

export default ProductContent
