import * as API from 'constants/api'
import { prop } from 'ramda'
import axios, { getPayloadFromError, getPayloadFromSuccess } from 'utils/axios'

export const getProductList = ({ actionType, ...param }) => {
  return (dispatch, getState) => {
    const params = {
      thumbnail_type: 'large',
      page_size: 8,
      ordering: prop('ordering', param)
    }

    const payload = axios({ dispatch, getState })
      .get(API.PRODUCT_LIST, { params })
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      payload: payload,
      type: actionType
    })
  }
}
