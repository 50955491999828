import * as ROUTES from 'constants/routes'
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { isEmpty, path, prop, propOr, reverse } from 'ramda'
import Container from 'components/StyledElems/Container'
import { OrderingButton } from 'components/UI/Button'
import ProductCard from 'components/Cards/ProductCard'
import Pagination from 'components/Pagination'
import Skelet from 'components/UI/Skelet/Skelet'
import { Row } from 'components/Grid'
import { getItemFromTree } from 'utils/get'
import Breadcrumbs from 'components/Breadcrumbs'
import { sprintf } from 'sprintf-js'
import Filter from './Filter'
import { getFullTreeForItem } from '../../../utils/get'
import GroupLinks from '../../../components/UI/List/GroupLinks'
import Link from '../../../components/Link'

const Wrap = styled(Container)`
  padding: 0 15px 15px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
`
const ProductsList = styled('div')`
  width: calc(100% - 267px);
  padding: 35px 0 40px 25px;
`
const ProductListBlock = styled('div')`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  border-radius: 5px;
`
const NoOptions = styled('div')`
  padding: 15px 20px;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  border-bottom: 1px solid #efefef;
`

const EmptyProducts = styled('div')`
  margin: 20px 0 0;
  background: #fff;
  border-radius: 5px;
  padding: 20px;
  width: 100%;
`
const Pages = styled('div')`
  text-align: center;
  margin-top: 40px;
`
const Title = styled('h1')`
  font-size: 30px;
  line-height: 36px;
  color: #2e384c;
  font-weight: 700;
  margin: 0 0 21px;
`
const OrderingWrap = styled('div')`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 6px;
  & > *{
    margin-bottom: 10px;
    &:not(:last-child){
      margin-right: 30px;
    }
  }
`

const StyledLi = styled.li`
  * {
    font-weight: 500;
    color: #2e384c
  },
  * :hover{
    color: #40a9ff
  }
`

const Categories = props => {
  const {
    productCategoryData,
    filterData,
    menuItems = [],
    id,
    handleSetOrdering
  } = props

  // Location
  const queryParams = prop('queryParams', filterData)
  const orderingQuery = prop('ordering', queryParams)

  // Const
  const category = getItemFromTree(menuItems, id)
  const treeCategory = reverse(getFullTreeForItem(menuItems, id))
  const categoryName = prop('name', category)
  const categoryChildren = propOr([], 'children', category)
  const items = path(['results'], productCategoryData)
  const count = path(['data', 'count'], productCategoryData)
  const loading = path(['loading'], productCategoryData)

  const childCategory = isEmpty(categoryChildren) ? <NoOptions>Категории отсутствуют</NoOptions>
    : (
      <GroupLinks
        label={'Категории'}
        list={category}
      >
        {categoryChildren.map(item => {
          const itemId = prop('id', item)
          return (
            <StyledLi
              key={itemId}
            >
              <Link
                to={{ pathname: sprintf(ROUTES.CATEGORIES_URL, itemId) }}
              >
                <span>
                  {prop('name', item)}
                </span>
              </Link>
            </StyledLi>
          )
        })}
      </GroupLinks>
    )

  // FiltersBar
  const filtersBar =
    <Filter
      addChildren={childCategory}
      {...filterData}
    />

  // Pagination
  const pagination =
    <Pages>
      <Pagination count={count} pageSize={12} />
    </Pages>

  // OrderingButtons
  const orderingData = [
    {
      label: 'Сначала новые',
      value: 'new',
      primary: true
    },
    {
      label: 'Сначала дешёвые',
      value: 'price'
    },
    {
      label: 'Сначала дорогие',
      value: '-price'
    }
  ]

  const ordering =
    <OrderingWrap>
      {orderingData.map((item, index) => {
        const label = prop('label', item)
        const value = prop('value', item)
        const isPrimary = prop('primary', item)
        const isActive = (value === orderingQuery) || (!orderingQuery && isPrimary)
        const handleOrdering = () => {
          if (!isActive) {
            handleSetOrdering(value)
          }
        }

        return (
          <OrderingButton
            label={label}
            active={isActive}
            onClick={handleOrdering}
            key={index}
          />
        )
      })}
    </OrderingWrap>

  // ProductsList
  const productsList =
    <Row>
      {loading
        ? <Skelet count={9} col={3} />
        : (
          isEmpty(items)
            ? <EmptyProducts>Продукты не найдены</EmptyProducts>
            : (
              <ProductListBlock>
                {items.map((item, key) => {
                  return (
                    <ProductCard
                      key={key}
                      item={item}
                      column={3}
                    />
                  )
                })}
              </ProductListBlock>
            )
        )}
    </Row>

  // Products
  const products =
    <ProductsList>
      <Breadcrumbs
        way={[
          {
            title: 'Главная',
            url: ROUTES.HOME
          },
          ...treeCategory.map((item, key) => {
            const itemCategory = prop('name', item)
            const itemId = prop('id', item)
            return (key !== treeCategory.length - 1) && {
              title: itemCategory,
              url: sprintf(ROUTES.CATEGORIES_URL, itemId)
            }
          })
        ]}
        currentWay={categoryName}
        style={{ marginBottom: '15px' }}
      />
      <Title>
        {categoryName}
      </Title>
      {ordering}
      {productsList}
      {pagination}
    </ProductsList>

  // Render
  return (
    <>
      <Wrap>
        {filtersBar}
        {products}
      </Wrap>
    </>
  )
}

Categories.propTypes = {
  productCategoryData: PropTypes.object,
  filterData: PropTypes.object,
  onChange: PropTypes.func,
  id: PropTypes.number,
  menuItems: PropTypes.array
}
export default Categories
