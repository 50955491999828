import * as API from 'constants/api'
import * as actionTypes from 'constants/actionTypes'
import axios, { getPayloadFromError, getPayloadFromSuccess } from 'utils/axios'
import { path, prop } from 'ramda'
import { setCookie } from 'utils/cookie'
import { sprintf } from 'sprintf-js'

export const signInAction = (username, code) => {
  return (dispatch, getState) => {
    const params = {
      username: `+998${username}`,
      code
    }

    const payload = axios({ dispatch, getState })
      .post(API.LOGIN, params)
      .then(response => {
        const resp = path(['data'], response)
        const token = path(['token'], resp)
        setCookie('token', token, 1)
        return resp
      })
      .catch(getPayloadFromError)

    return dispatch({
      payload,
      type: actionTypes.LOGIN
    })
  }
}

export const userInfoCheckToken = () => {
  return (dispatch, getState) => {
    const payload = axios({ dispatch, getState })
      .get(API.CHECK_TOKEN)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      payload,
      type: actionTypes.USER_INFO
    })
  }
}

export const configAction = () => {
  return (dispatch, getState) => {
    const payload = axios({ dispatch, getState })
      .get(API.CONFIG)
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.CONFIG,
      payload
    })
  }
}

export const footerPagesAction = () => {
  return (dispatch, getState) => {
    const payload = axios({ dispatch, getState })
      .get(API.FOOTER_PAGES, { params: { page_size: 100 } })
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.FOOTER_PAGES,
      payload
    })
  }
}

export const footerPagesDetailAction = id => {
  return (dispatch, getState) => {
    const payload = axios({ dispatch, getState })
      .get(sprintf(API.FOOTER_PAGES_DETAIL, id))
      .then(getPayloadFromSuccess)
      .catch(getPayloadFromError)

    return dispatch({
      type: actionTypes.FOOTER_PAGES_DETAIL,
      payload
    })
  }
}

export const userSignOut = () => {
  return (dispatch, getState) => {
    const payload = axios({ dispatch, getState })
      .get(API.LOGOUT)
      .then(response => {
        setCookie('token', '')
        dispatch({ type: `${actionTypes.LOGIN}_CLEAR` })
        return prop('data', response)
      })
      .catch(() => {
        setCookie('token', '')
        dispatch({ type: `${actionTypes.LOGIN}_CLEAR` })
      })
    return dispatch({
      payload,
      type: `${actionTypes.LOGIN}`
    })
  }
}

export const registerAction = phone => {
  return (dispatch, getState) => {
    const params = {
      username: `+998${phone}`
    }

    const payload = axios({ dispatch, getState })
      .post(API.REGISTER, params)
      .then(getPayloadFromSuccess)

    return dispatch({
      payload,
      type: actionTypes.REGISTER
    })
  }
}
