import * as STATE from 'constants/stateNames'
import React, { useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { path, find, propEq, pathOr, prop, isEmpty } from 'ramda'
import { CartButton, Button } from 'components/UI/Button'
import Image from 'components/UI/Image'
import Price from 'components/UI/Price'
import ProductContent from 'components/UI/ProductContent'
import NoImage from 'images/NoImage.png'
import { getDataFromState } from 'utils/get'
import equals from 'fast-deep-equal'
import Link from 'components/Link/Link'
import { favouriteCreateAction, favouriteDeleteAction } from 'routes/favourite/actions'
import Tooltip from 'components/UI/Tooltip'
import { removeItemFrom } from 'routes/cart/actions'
import SalePrice from '../UI/SalePrice/SalePrice'
import { setItemToCart } from './storage'
import getCookie from 'utils/getCookie'

const StyledCard = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: space-between;
  min-height: 390px;
  width: ${props => props.column === 3 ? 'calc(100% / 3)' : '25%'};
  &:not(:last-child){
    border-right: 1px solid #e1e1e1;
  }
  border-top: 1px solid #e1e1e1;
  background: #fff;
  & > a {
    display: block;
    padding: 18px 25px 25px;
  }
  &:nth-child(-n+${props => props.column === 3 ? 3 : 4}){
    border-top: 0;
  }
  &:nth-child(${props => props.column === 3 ? 3 : 4}) {}
  &:nth-child(${props => props.column === 3 ? 3 : 4}n) {
    border-right: none;
  }
`
const ImagePosition = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 242px;
  height: 185px;
`
const PricePosition = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 4px;
`
const ProductContentPosition = styled.div`
  margin-top: 8px;
  font-size: 14px;
`
const ButtonPosition = styled.div`
  width: calc(100% - 36px);
  margin: 0 18px 25px;
  & > button {
    font-weight: 600;
    height: 46px;
  }
  & > div {
    margin-top: 0;
  }
`
const FavouriteButton = styled.div`
  position: absolute;
  top: 11px;
  right: 13px;
  svg {
    width: 24px;
    height: 24px;
    fill: ${({ favourite, theme }) => favourite ? theme.palette.red : theme.palette.white};
    stroke: ${({ favourite, theme }) => favourite ? theme.palette.red : theme.palette.primary};
    transition: all 0s;
  }
`

const defArr = []

const ProductCard = props => {
  const {
    item,
    column
  } = props

  const cartList = useSelector(getDataFromState(STATE.CART), equals)

  const products = pathOr(defArr, ['data'], cartList)
  const dispatch = useDispatch()
  const name = path(['name'], item)
  const id = path(['id'], item)
  const price = path(['price'], item)
  const oldPrice = prop('oldPrice', item)
  const images = pathOr(defArr, ['images'], item)
  const isFavourite = path(['isFavourite'], item)
  const isPrimary = find(propEq('isPrimary', true))(images)
  const cartProduct = find(propEq('id', id))(products)
  const idChecker = path(['id'], cartProduct)
  const amount = prop('amount', cartProduct)
  const isAuth = !!getCookie('token')
  const measurement = prop('measurement', item)
  const measurementName = prop('name', measurement)
  const isCustomWeight = measurementName &&
    (measurementName.toLowerCase() === 'кг' || measurementName.toLowerCase() === 'kg')

  const mathValue = isCustomWeight ? 0.2 : 1

  const [favourite, setFavourite] = useState(isFavourite)

  const onFavourite = () => {
    setFavourite(!favourite)
    favourite ? dispatch(favouriteDeleteAction(id)) : dispatch(favouriteCreateAction(id))
  }

  const handleAddFirst = product => dispatch(setItemToCart(mathValue, product))
  const handleAdd = (product, value) => {
    return dispatch(setItemToCart((+value + mathValue).toFixed(1), product))
  }
  const handleRemove = (product, value) => {
    return dispatch(setItemToCart((+value - mathValue).toFixed(1), product))
  }

  const onDelete = () => {
    return dispatch(removeItemFrom(id))
  }

  // FavouriteButton
  const favouriteButton =
    <FavouriteButton favourite={favourite} onClick={onFavourite}>
      <Tooltip favourite={favourite} onClick={onFavourite} />
    </FavouriteButton>

  return (
    <StyledCard column={column}>
      {isAuth && favouriteButton}
      <Link to={'/product/' + id}>
        <ImagePosition>
          <Image
            src={isEmpty(images) ? NoImage : prop('file', isPrimary)}
            alt="image"
          />
        </ImagePosition>
        <PricePosition>
          <Price price={price} withDiscount={oldPrice && +oldPrice !== 0} />
          <SalePrice price={oldPrice} />
        </PricePosition>
        <ProductContentPosition>
          <ProductContent content={name} />
        </ProductContentPosition>
      </Link>
      <ButtonPosition>
        {id === idChecker ? (
          <CartButton
            amount={amount}
            onAdd={() => handleAdd(item, amount)}
            onRemove={() => handleRemove(item, amount)}
            onDelete={onDelete}
            measurement={measurementName}
            withDelete={true}
            withSuffix={true}
          />
        ) : (
          <Button
            onClick={() => handleAddFirst(item)}
            fullWidth={true}
          >
            В корзину
          </Button>
        )}
      </ButtonPosition>
    </StyledCard>
  )
}

ProductCard.propTypes = {
  item: PropTypes.object,
  column: PropTypes.number
}

export default ProductCard
