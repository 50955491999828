import * as API from 'constants/api'
import * as ROUTES from 'constants/routes'
import React, { useState, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import fetch from 'node-fetch'
import { find, isEmpty, pathOr, pipe, prop, propEq, propOr } from 'ramda'
import Link from 'components/Link'
import { sprintf } from 'sprintf-js'
import AbortController from 'abort-controller'
import NoImage from 'images/NoImage.png'
import numberFormat from 'utils/numberFormat'
import Button from 'components/Forms/Button'
import { CartButton } from 'components/UI/Button'
import { setItemToCart } from 'components/Cards/storage'
import { useDispatch, useSelector } from 'react-redux'
import { getDataFromState } from 'utils/get'
import * as STATE from 'constants/stateNames'
import equals from 'fast-deep-equal'
import { removeItemFrom } from 'routes/cart/actions'
import toCamelCase from 'utils/toCamelCase'

const SearchBlock = styled.div`
  position: relative;
  width: calc(100% - 720px);
  padding-left: 37px;
  display: flex;
  transition: width .2s, margin-left .2s;
  ${props => props.isSearch && css`
    width: calc(100% - 720px + 240px);
    margin-left: -240px;
  `}
`
const SearchFieldInput = styled.input`
  height: 50px;
  width: calc(100% - 75px);
  border: none;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  outline: 0;
  padding-left: 20px;
`
const SearchButton = styled.button`
  border: none;
  height: 50px;
  width: 75px;
  background: ${({ theme }) => theme.palette.primaryLight};
  color: white;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  outline: 0;
  cursor: pointer;
  user-select: none;
`
const SearchResults = styled('div')`
  display: ${({ isSearch }) => isSearch ? 'block' : 'none'};
  position: absolute;
  top: 60px;
  background: ${({ theme }) => theme.palette.white};
  box-shadow: ${({ theme }) => theme.boxShadow.modal};
  left: 37px;
  right: 0;
  border-radius: 5px;
  z-index: 100;
  overflow-y: auto;
  max-height: 500px;
  transition: all .2s ease-in-out;
`
const ResultContainer = styled('div')`
  padding: 10px 10px 5px;
`
const Product = styled('div')`
  position: relative;
  padding-bottom: 10px;
  &:not(:last-child){
    border-bottom: ${({ theme }) => theme.border.primary};
    margin-bottom: 10px;
  }
  a{
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 8px 0;
    border-radius: 5px;
    width: 100%;
  }
  & img{
    width: 70px;
    height: 70px;
    margin-right: 10px;
  }
  &:hover {
    color: unset;
    a{
      background: #f9f9f9;
    }
  }
`
const ButtonWrap = styled('div')`
  position: absolute;
  left: 88px;
  bottom: 20px;
  z-index: 1000;
  & > button{
    min-height: 32px;
    font-weight: 400;
    font-size: 12px;
    min-width: 95px;
  }
  & > div{
    margin-top: 0;
  }
`
const ProductRightSide = styled('div')`
  width: calc(100% - 90px);
  padding: 2px 8px 38px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 16px;
  line-height: 18px;
  color: ${({ theme }) => theme.color.secondary};
  h6 {
    font-weight: 500;
    margin: 0 0 4px;
    font-size: 15px;
    line-height: 20px;
  }
`
const Price = styled('span')`
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  margin: 0 0 3px 10px;
  color: ${({ theme }) => theme.palette.primary};
`
const NoResults = styled('div')`
  color: ${({ theme }) => theme.color.secondary};
  padding: 5px 8px 10px;
`

// Const Global
const FALSE = false
const EMPTY_ARR = []
const EMPTY_STR = ''

const SearchField = ({ routes }) => {
  // useState
  const [isSearch, setIsSearch] = useState(FALSE)
  const [loading, setLoading] = useState(FALSE)
  const [products, setProducts] = useState(EMPTY_ARR)
  const [search, setSearch] = useState(EMPTY_STR)
  const [prevController, setAbort] = useState(null)
  const [isHover, setIsHover] = useState(false)

  const pathname = prop('pathname', routes)

  // useEffect
  useEffect(() => {
    setIsSearch(false)
  }, [setIsSearch, pathname])

  // useEffect
  useEffect(() => {
    if (isSearch) {
      const controller = new AbortController()

      if (!prevController) {
        setAbort(controller)
      } else {
        prevController.abort()
        setAbort(controller)
      }

      setLoading(true)
      fetch(API.API_URL + API.PRODUCT_LIST + '?thumbnail_type=small&search=' + search, { signal: controller.signal })
        .then(response => response.json())
        .then(
          pipe(prop('results'), toCamelCase, setProducts, () => setLoading(false)),
          error => {
            setLoading(false)
            if (error.name === 'AbortError') {
              // eslint-disable-next-line no-console
              console.warn(error)
            }
          },
        )
    } else {
      setProducts([])
    }
  // eslint-disable-next-line
  }, [isSearch, search])

  // Ref
  const searchRef = useRef(null)

  // useEffect
  useEffect(() => {
    const listener = event => {
      if (!searchRef.current.contains(event.target) && !isHover) {
        setIsSearch(false)
      }
    }
    window.addEventListener('click', listener)
    return () => window.removeEventListener('click', listener)
    // eslint-disable-next-line
  }, [isSearch, search, isHover])

  // Const
  const dispatch = useDispatch()
  const cartList = useSelector(getDataFromState(STATE.CART), equals)
  const datas = pathOr([], ['data'], cartList)

  // HandleFunctions
  const onFocus = () => setIsSearch(true)
  const handleAddFirst = (product, mathValue) => dispatch(setItemToCart(mathValue, product))
  const handleAdd = (product, value, mathValue) => {
    return dispatch(setItemToCart((+value + mathValue).toFixed(1), product))
  }
  const handleRemove = (product, value, mathValue) => {
    return dispatch(setItemToCart((+value - mathValue).toFixed(1), product))
  }
  const handleDelete = id => {
    return dispatch(removeItemFrom(id))
  }

  // Render
  return (
    <SearchBlock
      isSearch={isSearch}
      ref={searchRef}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <SearchFieldInput
        onFocus={onFocus}
        onChange={ev => setSearch(ev.target.value)}
        placeholder={'Я хочу найти . . .'}
      />
      <SearchButton>найти</SearchButton>
      <SearchResults
        isSearch={isSearch}
      >
        <ResultContainer>
          {loading &&
          <NoResults>
            Поиск товаров . . . .
          </NoResults>}
          {products.map(item => {
            const productId = prop('id', item)
            const productName = prop('name', item)
            const price = prop('price', item)
            const images = propOr([], 'images', item)
            const isPrimary = find(propEq('isPrimary', true))(images)
            const primaryImg = prop('file', isPrimary) || NoImage
            const filterProduct = find(propEq('id', productId))(datas)
            const amount = pathOr(0, ['amount'], filterProduct)
            const measurement = prop('measurement', item)
            const measurementName = prop('name', measurement)

            const isCustomWeight = measurementName &&
              (measurementName.toLowerCase() === 'кг' || measurementName.toLowerCase() === 'kg')

            const mathValue = isCustomWeight ? 0.2 : 1

            return (
              <Product
                key={productId}
              >
                <ButtonWrap>
                  {filterProduct
                    ? (
                      <CartButton
                        amount={amount}
                        onDelete={() => handleDelete(productId)}
                        onAdd={() => handleAdd(item, amount, mathValue)}
                        onRemove={() => handleRemove(item, amount, mathValue)}
                        minWidth={'95px'}
                        height={'32px'}
                        measurement={measurementName}
                        fontSize={'14px'}
                        withDelete={true}
                      />
                    )
                    : (
                      <Button
                        text={'В корзину'}
                        type={'button'}
                        onClick={() => handleAddFirst(item, mathValue)}
                      />
                    )}
                </ButtonWrap>
                <Link
                  to={sprintf(ROUTES.PRODUCT_ITEM_URL, productId)}
                >
                  <img
                    src={primaryImg}
                    alt={productName}
                  />
                  <ProductRightSide>
                    <h6>{productName}</h6>
                    <Price>{numberFormat(price, 'сум')}</Price>
                  </ProductRightSide>
                </Link>
              </Product>
            )
          })}
          {(!loading && isEmpty(products)) &&
          <NoResults>
            Товары не найдены
          </NoResults>}
        </ResultContainer>
      </SearchResults>
    </SearchBlock>
  )
}

export default SearchField
