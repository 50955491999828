
import React from 'react'
import SvgIcon from './SvgIcon'

export default () => {
  return (
    <SvgIcon viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.75 12V5.99999C15.7497 5.73694 15.6803 5.4786 15.5487 5.25086C15.417 5.02312 15.2278 4.83401 15 4.70249L9.75 1.70249C9.52197 1.57084 9.2633 1.50153 9 1.50153C8.7367 1.50153 8.47803 1.57084 8.25 1.70249L3 4.70249C2.7722 4.83401 2.58299 5.02312 2.45135 5.25086C2.31971 5.4786 2.25027 5.73694 2.25 5.99999V12C2.25027 12.263 2.31971 12.5214 2.45135 12.7491C2.58299 12.9769 2.7722 13.166 3 13.2975L8.25 16.2975C8.47803 16.4291 8.7367 16.4985 9 16.4985C9.2633 16.4985 9.52197 16.4291 9.75 16.2975L15 13.2975C15.2278 13.166 15.417 12.9769 15.5487 12.7491C15.6803 12.5214 15.7497 12.263 15.75 12Z" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M2.45312 5.22L9.00063 9.0075L15.5481 5.22" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9 16.56V9" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
  )
}
