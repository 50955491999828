import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const TextAreaField = styled.textarea`
    height: 100px;
    border-radius: 7px;
    padding: 13px;
    float: right;
    width: 100%;
    border: 1px solid #d9dbde;
    outline: 0;
    background: ${({ theme }) => theme.background.primary};
`
const TextArea = props => {
  return (
    <TextAreaField {...props.input} placeholder={'Оставьте комментарий'} />
  )
}

TextArea.propTypes = {
  input: PropTypes.object
}

export default TextArea
