export const PRODUCT_LIST = 'productList'
export const PRODUCT_LIST_NEW = 'productListNew'
export const PRODUCT_LIST_POPULAR = 'productListPopular'
export const PRODUCT_LIST_MONTH = 'productListMonth'
export const PRODUCT_ITEM = 'productItem'

export const ADDRESS_LIST = 'addressList'
export const ADDRESS_CREATE = 'addressCreate'

export const ACTIVATE_MAILING = 'activateMailing'
export const DEACTIVATE_MAILING = 'deactivateMailing'

export const FAVOURITE_CREATE = 'favouriteCreate'
export const FAVOURITE_LIST = 'favouriteList'

export const USER_INFO = 'userInfo'
export const MENU_AS = 'menuAs'
export const CONFIG = 'config'

export const FOOTER_PAGES = 'footerPages'
export const FOOTER_PAGES_DETAIL = 'footerPagesDetail'

export const CART = 'cart'
export const ORDER_CREATE = 'orderCreate'
export const ORDER_LIST = 'orderList'
export const ORDER_ITEM = 'orderItem'

export const REGISTER = 'register'
export const LOGIN = 'login'

export const FILTER_LIST = 'filterList'

export const PRODUCT_CATEGORY_LIST = 'productCategoryList'

export const COMMENT_CREATE = 'commentCreate'
export const COMMENT_LIST = 'commentList'

export const CLIENT_UPDATE = 'clientUpdate'
export const DELIVERY_TYPE_LIST = 'deliveryTypeList'
export const LANGUAGE_LIST = 'languageList'

export const ROUTER = 'router'
