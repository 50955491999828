import React from 'react'
import Container from 'components/Container'
import styled from 'styled-components'
import { Col, Row } from 'components/Grid'
import Logo from 'images/Logo.png'
import Link from 'components/Link'
import { useSelector } from 'react-redux'
import { getDataFromState } from 'utils/get'
import * as STATE from 'constants/stateNames'
import equals from 'fast-deep-equal'
import { prop, pathOr, filter, propEq, isEmpty } from 'ramda'
import moment from 'moment'
import * as ROUTES from 'constants/routes'
import { sprintf } from 'sprintf-js'
import mapIndexed from 'utils/mapIndexed'

const Wrapper = styled.div`
  position: relative;
  z-index: 3;
  padding: 57px 0 54px;
  border-top: 1px solid #e1e1e1;
  background: ${({ theme }) => theme.background.primary};
`
const FooterTitle = styled('div')`
  display: block;
  padding: 5px 5px 10px;
  cursor: ${({ onClick }) => onClick ? 'pointer' : 'default'};
  font-size: 15px;
  font-weight: 600;
`
const LogoTitle = styled(Link)`
  padding: 5px 5px 23px 0;
  display: block;
`
const CopyrightText = styled('p')`
  padding: 3px 5px 3px 0;
  margin: 0;
`
const FooterSubTitle = styled.div`
  padding: 5px;
  cursor: pointer;
`
const FooterLink = styled(Link)`
  padding: 5px;
  cursor: pointer;
  display: block;
  color: ${({ theme }) => theme.palette.black};
  &:hover{
    color: ${({ theme }) => theme.palette.black};
  }
`

const Footer = () => {
  // Data
  const config = useSelector(getDataFromState(STATE.CONFIG), equals)
  const footerPages = useSelector(getDataFromState(STATE.FOOTER_PAGES), equals)
  const configData = prop('data', config)
  const footerPagesData = pathOr([], ['data', 'results'], footerPages)

  // Configs
  const phoneNumber = prop('pHONENUMBER', configData)
  const phoneNumberSecond = prop('pHONENUMBERSECOND', configData)
  const phoneNumberText = prop('pHONENUMBERTEXT', configData)
  const phoneNumberSecondText = prop('pHONENUMBERSECONDTEXT', configData)
  const footerAboutText = prop('fOOTERABOUTTEXT', configData)

  // Const
  const footerPagesAbout = filter((propEq('groupType', 'about')), footerPagesData)
  const footerPagesSales = filter((propEq('groupType', 'sales')), footerPagesData)
  const footerPagesPartner = filter((propEq('groupType', 'partner')), footerPagesData)

  // Handlers
  const handleCall = () => window.location.href = `tel:+${phoneNumber}`
  const handleCallSecond = () => window.location.href = `tel:+${phoneNumberSecond}`

  // List
  const list = arr => {
    return mapIndexed((item, index) => {
      return (
        <FooterLink
          key={index}
          to={sprintf(ROUTES.STATIC_PAGE_ITEM_URL, prop('link', item))}
        >
          {prop('name', item)}
        </FooterLink>
      )
    }, arr)
  }

  // Render
  return (
    <Wrapper>
      <Container>
        <Row>
          <Col>
            <LogoTitle to={'/'}><img src={Logo} alt="Logo" /></LogoTitle>
            <CopyrightText>© 2019-{moment().format('YYYY')} ООО «Lochin»</CopyrightText>
            <CopyrightText>Все права защищены</CopyrightText>
          </Col>
          <Col>
            <FooterTitle>
              О компании
            </FooterTitle>
            {!isEmpty(footerPagesAbout) && list(footerPagesAbout)}
          </Col>
          <Col>
            <FooterTitle>
              Покупателям
            </FooterTitle>
            {!isEmpty(footerPagesSales) && list(footerPagesSales)}
          </Col>
          <Col>
            <FooterTitle>
              Партнерам
            </FooterTitle>
            {!isEmpty(footerPagesPartner) && list(footerPagesPartner)}
          </Col>
          <Col>
            <FooterTitle>
              {footerAboutText}
            </FooterTitle>
            <FooterSubTitle
              onClick={handleCall}
            >
              {phoneNumberText}
            </FooterSubTitle>
            <FooterSubTitle
              onClick={handleCallSecond}
            >
              {phoneNumberSecondText}
            </FooterSubTitle>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  )
}

Footer.propTypes = {
}

export default Footer
